(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:RestUtilsFactory
   *
   * @description
   *
   */
  angular
    .module('utils')
    .factory('RestUtilsFactory', RestUtilsFactory);

  function RestUtilsFactory(
    $q,
    $log,
    UtilsFactory
  ) {
    return {
      getFullList: function (factory, parameters, subElement, list) {
        var actualParameters = parameters || {},
            actualList = list || [],
            actualSubElement = subElement || false,
            actualArguments = [];

        if (!actualParameters.limit) {
          actualParameters.limit = 99;
        }

        if (!actualParameters.offset) {
          actualParameters.offset = 0;
        }

        if (actualSubElement) {
          actualArguments.push(actualSubElement);
        }

        actualArguments.push(actualParameters);

        return $q(function (resolve, reject) {
          var offsetsToRetrieve = [],
              remainder = 0,
              blockSize = actualParameters.limit,
              currentOffsetValue = 0;
          // Call apply function on factory, passing this and argument array.
          // This call will retrieve the initial block of data, along with a count value
          // telling us the total number of results.
          factory.getList.apply(factory, actualArguments)
          .then(function (results) {
            // collect results, either from subarray "data" of just the response
            if (angular.isUndefined(results.count)) {
              angular.forEach(results.data, function (result) {
                actualList.push(result);
              });
            } else {
              angular.forEach(results, function (result) {
                actualList.push(result);
              });
            }

            // determine the number of results that still need to be fetched
            if (angular.isUndefined(results.count)) {
              if (angular.isUndefined(results.data)) {
                remainder = results.length;
              } else {
                remainder = results.data.count;
              }
            } else {
              remainder = results.count;
            }
            remainder -= blockSize;

            // set current offset value to blockSize since we already fetched the first block
            currentOffsetValue = blockSize;

            // determine all the offsets that still need to be fetched
            while (remainder > 0) {
              offsetsToRetrieve.push(currentOffsetValue);
              currentOffsetValue += blockSize;
              remainder -= blockSize;
            }

            // retrieve all the offsets asynchronously and resolve when it's done.
            UtilsFactory.promiseLoop(offsetsToRetrieve, function (offset) {
              actualParameters.offset = offset;
              return factory.getList.apply(factory, actualArguments)
              .then(function (offsetResults) {
                actualList = actualList.concat(offsetResults);
              });
            })
            .then(function () {
              $log.debug('RestUtilsFactory::getFullList() -> Fetched ' + actualList.length + ' records.');
              resolve(actualList);
            }, function (errorResponse) {
              reject(errorResponse);
            });
          }, function (errorResponse) {
            // call to apply failed for some reason
            reject(errorResponse);
          });
        });
      }
    };
  }
}());
