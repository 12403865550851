(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:CustomerFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('CustomerFactory', CustomerFactory);

  function CustomerFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'customer'});
    })
    .service('customers');
  }
}());
