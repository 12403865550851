(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.controller:KioskCtrl
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .controller('KioskCtrl', KioskCtrl);

  function KioskCtrl(
    $q,
    $cookies,
    $filter,
    $localStorage,
    $location,
    $log,
    $modal,
    moment,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $interval,
    $window,
    ENVIRONMENTS,
    CurrentUserContextFactory,
    EndpointFactory,
    EventFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    FitnessFactory,
    FitnessEventParticipationService,
    LocaleService,
    MyEntitiesFactory,
    OAuth,
    OAuthToken,
    ProductFactory,
    PromocodeFactory,
    Restangular,
    RestUtilsFactory,
    SaleFactory,
    SettingsService,
    ShoppingCartService,
    ToastrNotificationService,
    UserMeFactory,
    UserContextFactory,
    UtilsFactory,
    $translate,
    PARAMS,
    SubscriptionFactory,
    CustomerFactory
  ) {
    var vm = this;
    vm.components = [
      {
        name: 'RESERVATIONS',
        isVisible: true,
        init: function () {}
      },
      {
        name: 'MYRESERVATIONS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'MYJOURNALS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'HISTORY',
        isVisible: false,
        init: initHistory
      },
      {
        name: 'INDIVIDUALTICKETS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'GROUPLESSONINDIVIDUALTICKETS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'SHOP',
        isVisible: false,
        init: initWebshop
      },
      {
        name: 'CART',
        isVisible: false,
        init: initWebshopCart
      }
    ];

    /* ----- PARAMS ----- */
    vm.multipleSubscribeActionIsOpen = false;
    vm.eventsForDispayStatusMessage = [];
    vm.failedLogin = false;
    vm.selectUserContextFailed = false;
    vm.contactHasCredits = false;
    vm.contactFitnessCredits = null;
    vm.calendar = {};
    vm.categories = {};
    vm.customer = {
      id: $stateParams.Id
    };
    vm.customerContact = {};
    vm.fitnessEvents = [];
    vm.fitnessEventsAreLoading = true;
    vm.participationHistory = [];
    vm.journalModalInstance = null;
    vm.journals = {};
    vm.journalTypes = {};
    vm.products = [];
    vm.cartItems = {};
    vm.cartTotals = 0;
    vm.cartIndividualItems = {};
    vm.cartIndividualTotals = 0;
    vm.guest = {};
    vm.selectedDate = false;
    vm.selectedCategory = false;
    vm.showingJournalModal = false;
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };
    vm.calendarIsVisible = false;
    vm.historyIsVisible = false;
    vm.webshopIsVisible = false;
    vm.webshopCartIsVisible = false;
    vm.webshopSaleIsLoading = false;
    vm.giftBoxChooseSite = false;

    vm.filterPosition = [];

    vm.environments = ENVIRONMENTS.filter(function (e) {
      if (angular.isUndefined(e.visibleFrom)) {
        return true;
      }
      return (new Date(e.visibleFrom) <= new Date());
    });
    vm.currentSitesData = [];
    vm.guestCurrentSitesData = vm.environments;

    vm.config = [];
    vm.selectedBackend = 0;
    // set root UI variables
    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['kiosk']
    };
    vm.registeredEvents = 0;
    vm.availableCredits = 0;

    vm.passwordModalInstance = null;
    vm.passwordModalIsOpen = false;
    vm.landingPageModalInstance = null;
    vm.landingPageModalIsOpen = false;
    vm.newAccountModalInstance = null;
    vm.newAccountIsOpen = false;
    vm.selectActionIsOpen = false;
    vm.renewJournalModalInstance = null;
    vm.renewJournalIsOpen = false;
    vm.currentLocale = $translate.use();
    vm.defaultMaxProductsNumber = 9;
    vm.maxProductsNumber = vm.defaultMaxProductsNumber;
    vm.individualTicketsCount = 0;
    vm.myFitnessEvents = [];
    vm.editableFitnessEvents = [];
    vm.individualTicketsDatePickerIsOpened = false;
    vm.individualTicketsDatePickerMinDate = new Date();
    vm.eventsDate = new Date();
    vm.eventsDatePickerIsOpened = false;
    vm.currentDate = new Date();
    vm.eventsDatePickerMinDate = new Date(vm.currentDate.toLocaleString('en-US', { timeZone: 'Europe/Brussels' }));
    vm.currentPurchaseStep = 1;
    vm.purchaseProducts = [];
    vm.iceSkatingRentalProducts = [];
    vm.activePurchaseResidentButton = null;
    vm.individualTicketsDate = null;
    vm.eventsForIndividualTickets = [];
    vm.activityTypeForIndividualEvents = 'Recreatiebad';
    vm.selectedEventInstanceForIndividualTickets = null;
    vm.selectedEventInstanceForEdit = null;
    vm.noEventsWithAvailablePlacesForIndividual = false;
    vm.searchEventsDone = false;
    vm.promocodeDiscount = null;
    vm.cartDiscount = null;
    vm.cartIndividualTotalDiscount = null;
    vm.cartTotalDiscount = null;
    vm.promocodeValid = false;
    vm.promocodeChecked = false;
    vm.needReloadEvents = false;
    vm.waitForCustomerInfo = true;
    vm.displayLoginScreen = true;
    vm.loadingGiftProducts = true;
    vm.deliveryAddress = {};
    vm.differentDeliveryAddress = false;
    vm.needDelivery = false;
    vm.swimmingButtonBuyIndividualTickets = null;
    vm.swimmingButtonBuyProducts = null;
    vm.swimmingButtonLogin = null;
    vm.swimmingButtonBuyJournal = null;
    vm.swimmingButtonBuyGroupLessonsTickets = null;
    vm.grouplessonsButtonBuyIndividualTickets = null;
    vm.grouplessonsButtonBuyProducts = null;
    vm.grouplessonsButtonLogin = null;
    vm.grouplessonsButtonBuyJournal = null;
    vm.grouplessonsButtonBuyGroupLessonsTickets = null;
    vm.activeImg = null;
    vm.iceSkatingActive = false;
    vm.activePurchaseRentalButton = null;
    vm.iceSkatingSubCategory = null;
    vm.showPruchaseStep = showPruchaseStep;
    vm.getProductsByCategories = getProductsByCategories;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.divingRentalProducts = [];
    vm.divingProducts = [];
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.back = back;
    vm.changeStatus = changeStatus;
    vm.createNewAccount = createNewAccount;
    vm.dateToSeconds = dateToSeconds;
    vm.enableWebshop = false;
    vm.getAvailablePlacesText = getAvailablePlacesText;
    vm.getButtonText = getButtonText;
    vm.isDisplayAttentionMessage = isDisplayAttentionMessage;
    vm.isVisible = isVisible;
    vm.isComponentVisible = isComponentVisible;
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    vm.loadConfig = loadConfig;
    vm.loadCustomerData = loadCustomerData;
    vm.loadFitnessCredits = loadFitnessCredits;
    vm.loadFitnessEvents = loadFitnessEvents;
    vm.login = login;
    vm.registerAsGuest = registerAsGuest;
    vm.loginAsGuest = loginAsGuest;
    vm.logout = logout;
    vm.passwordForgot = passwordForgot;
    vm.returnOfPasswordModalInstance = returnOfPasswordModalInstance;
    vm.returnOfLandingPageModalInstance = returnOfLandingPageModalInstance;
    vm.selectAction = selectAction;
    vm.selectCategory = selectCategory;
    vm.selectDate = selectDate;
    vm.showButton = showButton;
    vm.showCalendar = showCalendar;
    vm.showUserContextSwitch = showUserContextSwitch;
    vm.showComponent = showComponent;
    vm.subscribeButtonDisabled = subscribeButtonDisabled;
    vm.initWebshopCart = initWebshopCart;
    vm.initHistory = initHistory;
    vm.initWebshop = initWebshop;
    vm.updateCategories = updateCategories;
    vm.checkForOneTimeDispkayEvent = checkForOneTimeDispkayEvent;
    vm.changeLocale = changeLocale;
    vm.getProductByTypeLabel = getProductByTypeLabel;
    vm.filterByEntrances = filterByEntrances;
    vm.allProducts = null;
    vm.getRejects = getRejects;
    vm.rejectionArray = [];
    vm.cancelParticipation = cancelParticipation;
    vm.getProductList = getProductList;
    vm.nextPurchaseStep = nextPurchaseStep;
    vm.previousPurchaseStep = previousPurchaseStep;
    vm.getProductListByCategories = getProductListByCategories;
    vm.getEventsForIndividualTickets = getEventsForIndividualTickets;
    vm.selectEventInstance = selectEventInstance;
    vm.editEventInstance = editEventInstance;
    vm.changeEventInstance = changeEventInstance;
    vm.checkPromocode = checkPromocode;
    vm.promocodeFieldValue = '';
    vm.setPromocode = setPromocode;
    vm.getEventsForCustomer = getEventsForCustomer;
    vm.getReservationsForCustomer = getReservationsForCustomer;
    vm.filterViaActivity = filterViaActivity;
    vm.finalizeSale = finalizeSale;
    vm.openNav = 'width: 0px; margin-left: 0px';
    vm.openSidebar = openSidebar;
    vm.actionSelectionModalInstance = null;
    vm.actionSelectionIsOpen = false;
    vm.displayDialog = displayDialog;
    vm.closeDialog = closeDialog;
    vm.guestEventUpdated = $rootScope.guestEventUpdated;
    vm.onlineReservationDisabled = angular.isDefined(PARAMS.settings) && angular.isDefined(PARAMS.settings.onlineReservationDisabled) ? PARAMS.settings.onlineReservationDisabled : false;
    vm.saleId = null;
    vm.addDeliveryProductToCart = addDeliveryProductToCart;
    vm.deliveryRequested = false;
    vm.isGuest = $stateParams.isGuest;
    vm.isGroupLesson = $stateParams.isGroupLesson;
    vm.setTicketCategories = setTicketCategories;
    vm.isGroupLessonTickets = false;
    vm.productMainCategory = 'Zwembad';
    vm.productCategoryChoosed = false;
    vm.webshopGroupLesson = false;
    vm.setWebshopProductCategory = setWebshopProductCategory;
    vm.siteSwitchingEnabled = SettingsService.get('onlineReservation.siteSwitchingEnabled', false);
    vm.residentCheckEnabled = SettingsService.get('onlineReservation.residentCheckEnabled', true);
    vm.hideWellnessActivityType = SettingsService.get('onlineReservation.hideWellnessActivityType', false);
    vm.getWelcomeText = getWelcomeText;
    vm.toggleClass = toggleClass;
    vm.switchActiveImg = switchActiveImg;
    vm.showResidentFilter = showResidentFilter;
    vm.showResidentFilterIceSkating = showResidentFilterIceSkating;
    vm.showRentalFilter = showRentalFilter;
    vm.getSportProductsByCategories = getSportProductsByCategories;
    vm.showSportFilter = showSportFilter;
    vm.sportProducts = false;
    vm.activeSportButton = null;
    vm.chooseSiteForGiftBox = chooseSiteForGiftBox;
    vm.buyGiftProducts = buyGiftProducts;
    vm.loginCustomerForPayment = loginCustomerForPayment;
    vm.loadSiteConfigForCustomer = loadSiteConfigForCustomer;
    vm.subscriptionJournalProduct = undefined;
    vm.swimmingCourseLessonLabel = null;
    vm.swimmingCourseLevelLabel = null;
    vm.addSubscriptionJournalProductToCart = addSubscriptionJournalProductToCart;
    vm.loadSubscriptionInfo = loadSubscriptionInfo;
    vm.subscriptionId = undefined;
    vm.activityTypesBasedOnProduct = [];
    vm.getActivityTypesBasedOnProducts = getActivityTypesBasedOnProducts;
    vm.enableCustomersCoins = SettingsService.get('customers.enableCoins', false);
    vm.loadCustomerCoinsInformation = loadCustomerCoinsInformation;
    vm.customerCoin = null;
    vm.amountOfProductsWithJournal = 1;
    vm.handleSiteClick = handleSiteClick;

    /* ----- END FUNCTIONS ----- */

    /* ----- ON INIT ----- */
    if ($state.current.name === 'kiosk') {
      SettingsService.reloadPublicSettings().then(function () {
        vm.kioskLandingPageFitnessEnabled = SettingsService.getPublicSetting('kiosk.landingPageFitnessEnabled', false);
        vm.landingPageGrouplessonsEnabled = SettingsService.getPublicSetting('onlineReservation.landingPageGrouplessonsEnabled', 'true');
        vm.landingPageWellnessEnabled = SettingsService.getPublicSetting('onlineReservation.landingPageWellnessEnabled', 'true');
        vm.navigationBuySwimmingTicketsEnabled = SettingsService.getPublicSetting('onlineReservation.navigationBuySwimmingTicketsEnabled', false);
        vm.navigationBuyOutdoorGrouplessonsEnabled = SettingsService.getPublicSetting('onlineReservation.navigationBuyOutdoorGrouplessonsEnabled', false);
        vm.loadingSettingForLandingPage = SettingsService.getPublicSetting('onlineReservation.landingsPageModal', false);
      }).then(function () {
        landingPageModal();
      });
    }
    //if access to login adn site is disabled then return back to main page
    if ($state.current.name === 'kiosk.login' && vm.onlineReservationDisabled) {
      $state.go('kiosk');
    }

    if ($state.current.name === 'kiosk.user') {
      vm.purchaseProducts = [];
      ProductFactory.getProductsByOptions({category: 'cadeaubox', allowEmptyResponse: true}).then(function (products) {
        vm.purchaseProducts = products;
        vm.loadingGiftProducts = false;
      });
    }

    if ($state.current.name === 'kiosk.swimmingschool-payment_process_payment') {
      if (angular.isDefined($stateParams.productId) && $stateParams.productId) {
        vm.subscriptionJournalProduct = $stateParams.productId;
        vm.addSubscriptionJournalProductToCart($stateParams.productId, 1);
      }
      if (angular.isDefined($stateParams.subscriptionId) && $stateParams.subscriptionId) {
        vm.subscriptionId = $stateParams.subscriptionId;
        vm.loadSubscriptionInfo($stateParams.subscriptionId);
      }
    }

    if ($state.current.name === 'kiosk.register_guest_user') {
      setEnvironmentFromLocalStorage().then(function () {
        if (angular.isUndefined($rootScope.registeringAsGuest)) {
          $rootScope.registeringAsGuest = true;
          return vm.registerAsGuest(vm.isGuest, vm.isGroupLesson);
        }
      });
    }

    // set variables for grouplessons or individual tickets for a guest page
    if ($state.current.name === 'kiosk.guest') {
      console.log($rootScope);
      vm.guestCategory = vm.isGroupLesson === 'true' ? 'GROUPLESSONINDIVIDUALTICKETS' : 'INDIVIDUALTICKETS';
      if ($localStorage.activityName === 'ice skating') {
        vm.guestCategory = 'ICESKATING';
        vm.iceSkatingRentalProducts = [];
        ProductFactory.getProductsByCategories(['Schaatsbaan', 'verhuur']).then(function (products) {
          vm.iceSkatingRentalProducts = products;
        });
      }
      if ($localStorage.activityName === 'squash') {
        vm.guestCategory = 'SQUASH';
        vm.squashRentalProducts = [];
        ProductFactory.getProductsByCategories(['squash']).then(function (products) {
          vm.squashRentalProducts = products;
        });
      }
      vm.setTicketCategories(vm.guestCategory);
      if (vm.isGroupLesson === 'true') {
        vm.purchaseProducts = [];
        ProductFactory.getProductsByCategories([vm.productMainCategory, 'individuele toegangen']).then(function (products) {
          vm.purchaseProducts = products;
        });
      }

      // special case for wellness
      // but this is a mess
      // we need to refactor this soon
      if ($localStorage.activityName === 'wellness') {
        vm.purchaseProducts = [];

        if ($localStorage.indvidualTicketsOption === 1) {
          ProductFactory.getProductsByCategories(['wellness', 'individuele toegangen', 'badenhuis']).then(function (products) {
            vm.purchaseProducts = products;
          });
        } else if ($localStorage.indvidualTicketsOption === 2) {
          ProductFactory.getProductsByCategories(['wellness', 'individuele toegangen', 'hammam']).then(function (products) {
            vm.purchaseProducts = products;
          });
        }
      }

      // special case for diving
      if ($localStorage.activityName === 'diving') {
        vm.purchaseProducts = [];

        if ($localStorage.indvidualTicketsOption === 1) {
          vm.divingRentalProducts = [];
          ProductFactory.getProductsByCategories(['individuele toegangen', 'Snorkelen']).then(function (products) {
            vm.purchaseProducts = products;
            vm.divingProducts = products;
          });
        } else if ($localStorage.indvidualTicketsOption === 2) {
          vm.divingRentalProducts = [];
          ProductFactory.getProductsByCategories(['individuele toegangen', 'Duiken']).then(function (products) {
            vm.purchaseProducts = products;
            vm.divingProducts = products;
          });
          ProductFactory.getProductsByCategories(['Duiken', 'verhuur']).then(function (products) {
            vm.divingRentalProducts = products;
          });
        } else if ($localStorage.indvidualTicketsOption === 3) {
          ProductFactory.getProductsByCategories(['individuele toegangen', 'Apneuduiken']).then(function (products) {
            vm.purchaseProducts = products;
            vm.divingProducts = products;
          });
        }
      }
    }

    if ($state.current.name === 'kiosk.customer' || $state.current.name === 'kiosk.guest' || $state.current.name === 'kiosk.user' || $state.current.name === 'kiosk.swimmingschool-payment_process_payment') {
      RestUtilsFactory.getFullList(EventParticipantStatusFactory, {limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
        vm.participantStatuses = resultStatuses;
      });
      EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
        vm.participantTypes = resultTypes;
      });
      SettingsService.reloadSettings().then(function () {
        vm.enableWebshop = angular.isDefined($localStorage.enableWebshop) || SettingsService.get('settings.enableWebshop', false);
      });

      // check if webshop sale was completed
      if ($location.search().sale_id) {
        vm.webshopSaleIsLoading = true;
        vm.saleId = $location.search().sale_id;

        vm.finalizeSale();
      }

      vm.loadCustomerData()
        .then(function () {
          vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
            if (cc.firstName === 'Proefbeurt') {
              vm.enableWebshop = true;
            }
            return cc.customerContactType.code === 'USER';
          })[0];
          vm.loadCompleteFitnessData();
          vm.enableCustomersCoins = SettingsService.get('customers.enableCoins', false);
          if (vm.enableCustomersCoins && $state.current.name === 'kiosk.customer') {
            vm.loadCustomerCoinsInformation();
          }
        });

      $interval(function () {
        UserMeFactory.one().get().then(function () {
          $log.debug('Reloaded the token !!!');
        });
      }, 600000);
    }

    if ($state.current.name === 'kiosk.choose_site') {
      if ($location.search().giftBox) {
        vm.giftBoxChooseSite = true;
      } else {
        vm.giftBoxChooseSite = false;
      }
    }

    if ($state.current.name === 'kiosk.guest_edit') {
      vm.loadCustomerData().then(function () {
        vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];
        getEditableReservationsForCustomer();
      });
    }

    if ($state.current.name === 'kiosk.guest_login') {
      setEnvironmentFromLocalStorage().then(function () {
        if (angular.isUndefined($rootScope.loggingInAsGuest)) {
          $rootScope.loggingInAsGuest = true;
          return vm.loginAsGuest();
        }
      }, function () {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      });
    }

    //extra route to redirect to swimmingschool payment login to be able to use new configs
    if ($state.current.name === 'kiosk.swimmingschool-payment_login') {
      vm.logout(true).finally(function () {
        vm.loadSiteConfigForCustomer($stateParams.token).then(function () {
            $window.location.href = '/swimmingschool-payment-login-customer/' + $stateParams.token;
          });
      });
    }

    //swimmingschool payment login
    if ($state.current.name === 'kiosk.swimmingschool-customer_login_as_guest') {
      setEnvironmentFromLocalStorage().then(function () {
        if (angular.isUndefined($rootScope.loggingInAsCustomer)) {
          $rootScope.loggingInAsCustomer = true;
          return vm.loginCustomerForPayment($stateParams.token);
        }
      }, function () {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      });
    }
    /* ----- END ON INIT ----- */

    $scope.$on('$viewContentLoaded', function () {
      setEnvironmentFromLocalStorage().then(function () {
      }, function () {
        if ($state.current.name !== 'kiosk.choose_site' && $state.current.name !== 'kiosk.guest_login') {
          $state.go('kiosk');
        }
      });
    });

    function setEnvironmentFromLocalStorage() {
      if ($localStorage.selectedBackend || (!$localStorage.selectedBackend && $localStorage.selectedBackend === 0)) {
        return $timeout(function () {
          $rootScope.environment = vm.environments[$localStorage.selectedBackend];
        });
      }
      return Promise.reject(new Error('no selected backend in localStorage'));
    }

    function filterViaActivity(activityName) {
      vm.activityName = activityName;
      $localStorage.activityName = activityName;

      if (activityName === 'swimming') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasSwimming;
        });
      }

      if (activityName === 'fitness') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasFitness;
        });
      }

      if (activityName === 'group lessons') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasGroupLessons;
        });
      }

      if (activityName === 'wellness') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasWellness;
        });
      }

      if (activityName === 'ice skating') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasIceSkating;
        });
      }

      if (activityName === 'diving') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasDiving;
        });
      }

      if (activityName === 'squash') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasSquash;
        });
      }

      if (activityName === 'facilityPlanning') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasFacilityPlanning;
        });
      }

      vm.filterPosition = vm.currentSitesData.map(function (element, index) {
        return {
          position: element.mapPosition,
          name: element.name,
          city: element.city,
          image: element.image,
          index: index,
          siteId: element.siteId
        };
      });

      var anchor = document.querySelector('.environment-container-new');
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    function finalizeSale(reset) {
      SaleFactory.isSaleComplete(vm.saleId).then(function (res) {
        vm.webshopSaleIsLoading = false;
        if (!res.completed) {
          ToastrNotificationService.showTranslatedNotification('warning', 'kiosk.webshop', 'product.buy_error');
        } else if (res.guest) {
          // guest users should see a thank you page after a successfull payment
          vm.deliveryRequested = angular.isDefined(res.deliveryRequested) ? res.deliveryRequested : false;
          vm.currentPurchaseStep = $state.current.name === 'kiosk.user' ? 4 : 5;
          if ($state.current.name === 'kiosk.swimmingschool-payment_process_payment') {
            vm.currentPurchaseStep = 2;
          }
        } else {
          ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', (res.withReservation ? 'product.buy_with_reservation_success' : 'product.buy_success'));
        }

        if (reset) {
          $window.location.href = $window.location + '?sale_id=' + vm.saleId;
        }
      });
    }

    function createNewAccount() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_create_account.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }

    function selectAction() {
      if (vm.selectActionIsOpen) {
        return;
      }
      vm.selectActionModalInstance = $modal.open({
        templateUrl: 'kiosk/views/create_new_account_for_new_customer.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl',
        resolve: {
          email: function () {
            return angular.isDefined(vm.credentials) && angular.isDefined(vm.credentials.email) ? vm.credentials.email : null;
          }
        }
      });
      vm.selectActionIsOpen = true;

      vm.selectActionModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      });
    }

    function passwordForgot() {
      // Show modal
      // Check if the modal view is already open
      if (vm.passwordModalIsOpen) {
        return;
      }
      // Open a modal view from specifi c template and controller. A customer object and customerContact object are added to the controller
      vm.passwordModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_password_forgot.modal.tpl.html',
        controller: 'UserPasswordForgotCtrl',
        controllerAs: 'userPasswordForgotCtrl'
      });
      vm.passwordModalIsOpen = true;
      vm.returnOfPasswordModalInstance();
    }

    function returnOfPasswordModalInstance() {
      vm.passwordModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      });
    }

    function landingPageModal() {
      // Show modal
      // Check if the modal view is already open
      if (vm.landingPageModalIsOpen) {
        return;
      }

      if (vm.loadingSettingForLandingPage === false) {
        return;
      }

      vm.landingPageModalInstance = $modal.open({
        templateUrl: 'kiosk/views/landing_page.modal.tpl.html',
        controller: 'LandingPageModalCtrl',
        controllerAs: 'landingPageModalCtrl'
      });
      vm.landingPageModalInstance.loadingSettingForLandingPage = vm.loadingSettingForLandingPage;
      vm.landingPageModalIsOpen = true;
      vm.returnOfLandingPageModalInstance();
    }

    function returnOfLandingPageModalInstance() {
      vm.landingPageModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.landingPageModalIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.landingPageModalIsOpen = false;
      });
    }

    function getRejects(eventInstance) {
      var rejectionArray = [];
      eventInstance.eventParticipants.forEach(function (participant) {
        if (participant.eventParticipantStatus.code === 'ACCEPTED' && participant.eventParticipantType.code === 'PARTICIPANT' && vm.customerContact.contact.id === participant.contact.id) {
          rejectionArray.push(participant);
        }
      });
      return rejectionArray;
    }

    function cancelParticipation(eventParticipant) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          var deferred = $q.defer(),
              participantObject = {
                eventParticipantStatus: rejectedStatus.id
              };
          // Set Rejected
          EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
            //reload data
            vm.loadCompleteFitnessData();
          });
          return deferred.promise;
        });
    }

    function loadCompleteFitnessData() {
      vm.availableCredits = null;
      vm.registeredEvents = 0;
      vm.fitnessEventsAreLoading = true;
      return FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact)
        .then(function (fitnessData) {
          $log.debug('KioskCtrl::loadFitnessEvents() -> FitnessData:', fitnessData);
          vm.contactFitnessCredits = fitnessData.credits;

          vm.journals = {};
          vm.journalTypes = {};
          angular.forEach(fitnessData.activeJournals, function (journal) {
            vm.journals[journal.id] = journal;
            if (angular.isDefined(vm.journalTypes[journal.journalType.id])) {
              vm.journalTypes[journal.journalType.id].push(journal.id);
            } else {
              vm.journalTypes[journal.journalType.id] = [journal.id];
            }
          });
          vm.waitForCustomerInfo = false;
          if (vm.contactFitnessCredits > 0) {
            vm.contactHasCredits = true;
          }

          vm.getReservationsForCustomer();
          if (vm.needReloadEvents) {
            return vm.getEventsForCustomer();
          }
        });
    }

    function changeStatus(status, eventInstance) {
      $log.debug('status: ', status);
      $log.debug('Kiosk: event: ', eventInstance.label);
      switch (status) {
        case 'cancel':
        case 'cancel-waitinglist':
          return FitnessEventParticipationService.multipleCancel(eventInstance).then(function () {
            $log.debug('Kiosk: event participation cancelled');
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation multiple cancel failed');
          });
        // return FitnessEventParticipationService.cancel(eventInstance).then(function () {
        //   $log.debug('Kiosk: event participation cancelled');
        //   return vm.loadCompleteFitnessData();
        // }, function () {
        //   $log.debug('Kiosk: event participation cancel failed');
        // });
        case 'subscribe':
          if (checkInstaceForMultipleSubscribing(eventInstance)) {
            return multipleSubscribe(eventInstance, vm.customerContact);
          }
          //extract to method single subscribe
          return FitnessEventParticipationService.subscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        case 'edit':
          if (checkInstaceForMultipleSubscribing(eventInstance)) {
            return multipleSubscribeEdit(eventInstance, vm.customerContact);
          }
          //extract to method single subscribe
          return FitnessEventParticipationService.subscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        case 'extra-subscribe':
          return FitnessEventParticipationService.extraSubscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        default:
          return Promise.reject();
      }
    }

    function checkInstaceForMultipleSubscribing(eventInstance) {
      var result = false;
      angular.forEach(vm.journals, function (journal) {
        if (journal.journalType.multipleConsumptions) {
          eventInstance.eligibleJournalTypeIds.forEach(function (element) {
            if (journal.journalType.id === element && journal.journalType.multipleConsumptions === true) {
              result = true;
            }
          });
        }
      });
      return result;
    }

    function checkForOneTimeDispkayEvent(idEvent) {
      if (vm.eventsForDispayStatusMessage.includes(idEvent)) {
        return true;
      }
      return false;
    }

    function multipleSubscribeEdit(eventInstance, customerContact) {
      var creditsFromJournals = 0;
      // if (vm.multipleSubscribeActionIsOpen) {
      //   return;
      // }
      vm.multipleSubscribeInstanceEdit = $modal.open({
        templateUrl: 'kiosk/views/multiple_subscribe.modal.edit.tpl.html',
        controller: 'MultipleSubscribeEditCtrl',
        controllerAs: 'multipleSubscribeEditCtrl'
      });

      angular.forEach(vm.journals, function (journal) {
        eventInstance.eligibleJournalTypeIds.forEach(function (element) {
          if (journal.journalType.id === element) {
            creditsFromJournals += angular.isDefined(journal.credits) ? journal.credits : 0;
          }
        });
      });

      vm.multipleSubscribeInstanceEdit.eventInstance = eventInstance;
      vm.multipleSubscribeInstanceEdit.customerContact = customerContact;
      creditsFromJournals = creditsFromJournals > eventInstance.numberOfVacancies ? eventInstance.numberOfVacancies : creditsFromJournals;
      vm.multipleSubscribeInstanceEdit.availableCredits = (creditsFromJournals > 0 && creditsFromJournals < vm.contactFitnessCredits) ? creditsFromJournals : vm.contactFitnessCredits;
      vm.multipleSubscribeInstanceEdit.result.then(function () {
        return vm.loadCompleteFitnessData();
      });
    }
    function multipleSubscribe(eventInstance, customerContact) {
      var i = 0, promicesOfSubscribes = [], creditsFromJournals = 0, rejectedParcipants = 0;
      if (vm.multipleSubscribeActionIsOpen) {
        return;
      }
      vm.multipleSubscribeInstance = $modal.open({
        templateUrl: 'kiosk/views/multiple_subscribe.modal.tpl.html',
        controller: 'MultipleSubscribeCtrl',
        controllerAs: 'multipleSubscribeCtrl'
      });

      angular.forEach(vm.journals, function (journal) {
        eventInstance.eligibleJournalTypeIds.forEach(function (element) {
          if (journal.journalType.id === element) {
            creditsFromJournals += angular.isDefined(journal.credits) ? journal.credits : 0;
          }
        });
      });

      // if there is more credits than available places, then show the amount of available places
      creditsFromJournals = creditsFromJournals > eventInstance.numberOfVacancies ? eventInstance.numberOfVacancies : creditsFromJournals;
      vm.multipleSubscribeInstance.availableCredits = (creditsFromJournals > 0 && creditsFromJournals < vm.contactFitnessCredits) ? creditsFromJournals : vm.contactFitnessCredits;
      vm.multipleSubscribeActionIsOpen = true;
      return vm.multipleSubscribeInstance.result.then(function (returnValue) {
        rejectedParcipants = eventInstance.eventParticipants.filter(function (element) {
          return element.eventParticipantStatus.code === 'REJECTED';
        });
        if (rejectedParcipants.length > 0 && rejectedParcipants.length >= vm.multipleSubscribeInstance.count) {
          rejectedParcipants.forEach(function () {
            if (vm.multipleSubscribeInstance.count > 0) {
              promicesOfSubscribes.push(new Promise(function (res) {
                return res(FitnessEventParticipationService.subscribeRejectedParcipants(eventInstance, customerContact));
              }));
              vm.multipleSubscribeInstance.count--;
            }
          });
        } else if (rejectedParcipants.length > 0 && rejectedParcipants.length < vm.multipleSubscribeInstance.count) {
          rejectedParcipants.forEach(function () {
            promicesOfSubscribes.push(new Promise(function (res) {
              return res(FitnessEventParticipationService.subscribeRejectedParcipants(eventInstance, customerContact));
            }));
          });
          for (i = 0; i < vm.multipleSubscribeInstance.count - rejectedParcipants.length; i++) {
            promicesOfSubscribes.push(new Promise(function (res) {
              return res(FitnessEventParticipationService.subscribeRejectedParcipants(eventInstance, customerContact));
            }));
          }
        } else {
          for (i = 0; i < vm.multipleSubscribeInstance.count; i++) {
            promicesOfSubscribes.push(new Promise(function (res) {
              return res(FitnessEventParticipationService.subscribe(eventInstance, customerContact));
            }));
          }
        }

        Promise.all(promicesOfSubscribes).then(function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        }, function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        });

        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      });
      // return vm.loadCompleteFitnessData();
    }

    function dateToSeconds(dateString) {
      return moment(dateString).unix();
    }

    function isVisible(eventInstance) {
      return eventInstance.eventCategories.filter(function (eventCategory) {
        return eventCategory.code === vm.selectedCategory || vm.selectedCategory === false;
      }).length;
    }

    function loadCustomerData() {
      return MyEntitiesFactory.getCustomer()
        .then(function (customer) {
          vm.customer = customer;
        });
    }

    function loadFitnessCredits() {
      return MyEntitiesFactory
        .getFitnessCredits()
        .then(function (resultCredits) {
          $log.debug('fitness credits', resultCredits);
          if (!angular.isArray(resultCredits)) {
            vm.contactFitnessCredits = resultCredits.credits;
            vm.contactHasCredits = true;
          } else {
            vm.contactHasCredits = false;
          }
        });
    }

    function loadFitnessEvents() {
      return FitnessFactory.loadFitnessEvents(vm.journals, vm.customerContact).then(function (results) {
        $log.debug('FitnessEvents: ', results);
        vm.fitnessEvents = results.events;
      });
    }

    function login() {
      vm.failedLogin = false;
      return OAuth.getAccessToken(vm.credentials)
        .then(function (response) {
          $log.debug('KIOSK: Form authentication successful', response);

          return fetchUser().then(function (customer) {
            vm.displayLoginScreen = false;
            $state.go('kiosk.customer', {customerId: customer.id});
          });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Form authentication failed' + errorResponse);
        });
    }

    function registerAsGuest(isGuest, isGroupLesson) {
      return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-and-login-as-guest')
        .customPOST({
          site: $rootScope.environment.siteId,
          locale: $translate.use().split('_')[0].toLowerCase()
        }).then(function (response) {
          $log.debug('KIOSK: Guest authentication successful', response);

          OAuthToken.setToken(response);

          return fetchUser().then(function (customer) {
            var stateTo = (angular.isDefined(isGuest) && isGuest === 'true') ? 'kiosk.guest' : 'kiosk.user';
            $state.go(stateTo, {customerId: customer.id, isGroupLesson: isGroupLesson});
          });
        }, function (errorResponse) {
          $log.debug('KIOSK: Guest authentication failed' + errorResponse);
        });
    }

    function loginAsGuest() {
      return vm.logout(true).finally(function () {
        return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/login-as-guest')
          .customPOST({
            token: $stateParams.token
          }).then(function (response) {
            $log.debug('KIOSK: Guest login successful', response);

            OAuthToken.setToken(response);

            return fetchUser().then(function (customer) {
              $state.go('kiosk.guest_edit', {customerId: customer.id});
            });
          }, function (errorResponse) {
            $log.debug('KIOSK: Guest login failed' + errorResponse);
          });
      });
    }

    function fetchUser() {
      return UserMeFactory.one().get()
        .then(function (user) {
          $log.debug('KIOSK: Fetched user' + user);
          LocaleService.syncBackendLocale(user.locale);
          $localStorage.userContexts = user.userContexts;
          CurrentUserContextFactory.set('userId', user.id);
          return CurrentUserContextFactory.autoSelectUserContext(user)
            .then(function () {
              return MyEntitiesFactory.getCustomer()
                .then(function (customer) {
                  if (customer) {
                    $log.debug('KIOSK: Fetched customer linked to user' + customer);
                    return Promise.resolve(customer);
                  }
                  vm.failedLogin = true;
                  $log.debug('K IOSK: No customer found linked to user' + user);
                  return Promise.reject();
                }, function (errorResponse) {
                  vm.failedLogin = true;
                  $log.debug('KIOSK: Failed to fetch customer linked to user' + errorResponse);
                  return Promise.reject();
                });
            }, function (error) {
              vm.failedLogin = true;
              vm.selectUserContextFailed = true;
              $log.debug('KIOSK: ' + error);
              OAuth.revokeToken();
              return Promise.reject();
            });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Failed to fetch user' + errorResponse);
          return Promise.reject();
        });
    }

    function logout(noRedirect) {
      var token = OAuthToken.getRefreshToken() ? OAuthToken.getRefreshToken() : OAuthToken.getAccessToken();
      vm.customerContact = {};
      vm.customer = {};
      vm.contactHasCredits = false;
      vm.contactFitnessCredits = null;
      angular.forEach($cookies, function (chip) {
        $cookies.remove(chip);
      });
      if (token) {
        return OAuth.revokeToken().then(function () {
          if (!noRedirect) {
            $state.go('kiosk');
          }
        });
      }

      return Promise.resolve();
    }

    function selectDate(date) {
      vm.selectedDate = date;
      vm.showCalendar(false);
    }

    function selectCategory(category) {
      vm.selectedCategory = vm.selectedCategory === category.code ? false : category.code;
    }

    function getButtonText(eventInstance, returnClass) {
      var valueToReturn = '';
      returnClass = angular.isUndefined(returnClass) ? false : returnClass;

      if (!eventInstance.numberOfVacancies) {
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else {
          valueToReturn = returnClass ? 'btn-warning' : 'app.subscribe_to_waiting_list';
        }
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else if (eventInstance.userStatus && eventInstance.userStatus.code === 'REJECTED' ||
          eventInstance.userStatus && eventInstance.userStatus.code === 'ACCEPTED') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.edit';
        } else {
          valueToReturn = returnClass ? 'btn-primary' : 'app.subscribe';
        }
      }
      return valueToReturn;
    }

    function getAvailablePlacesText(eventInstance) {
      var valueToReturn = '';
      if (!eventInstance.numberOfVacancies) {
        valueToReturn = 'kiosk.waiting_list';
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.numberOfVacancies > 1) {
          valueToReturn = 'kiosk.places_available';
        } else {
          valueToReturn = 'kiosk.place_available';
        }
      }
      return valueToReturn;
    }

    function isComponentVisible(component) {
      var comp = vm.components.filter(function (c) {
        return c.name === component;
      });
      return comp[0].isVisible;
    }

    function showComponent(component) {
      vm.components.filter(function (c) {
        return c.name !== component;
      }).forEach(function (c) {
        c.isVisible = false;
      });

      vm.components.filter(function (c) {
        return c.name === component;
      }).forEach(function (c) {
        c.isVisible = true;
        c.init();
      });

      vm.setTicketCategories(component);
    }

    function showButton(action, eventInstance) {
      var isTentative = false,
          isAccepted = false,
          isPending = false,
          isPendingWithReservationSale = false,
          participates = angular.isDefined(eventInstance.userStatus),
          attends = angular.isDefined(eventInstance.userStatus) &&
          angular.isDefined(eventInstance.userStatus.code) &&
          eventInstance.userStatus.code === 'ATTENDED';

      if (eventInstance.userStatus) {
        isTentative = eventInstance.userStatus.code === 'TENTATIVE';
        isAccepted = eventInstance.userStatus.code === 'ACCEPTED';
        isPending = eventInstance.userStatus.code === 'PENDING';
        isPendingWithReservationSale = eventInstance.userStatus.code === 'PENDING' && eventInstance.userStatus.hasReservationSale;
      }

      switch (action) {
        case 'subscribe':
          if (!isAccepted &&
            !isPending &&
            !attends &&
            (!isTentative || (isTentative && eventInstance.numberOfVacancies > 0)) &&
            // vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60)
            vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - eventInstance.reservationHours * 60 * 60)
          ) {
            // can subscribe until 3 hours before the event
            return true;
          }

          break;
        case 'cancel':
          if ((isAccepted || isTentative) &&
            vm.now.seconds < vm.dateToSeconds(eventInstance.registrationDeadline)
          ) {
            return true;
          }
          break;
        case 'edit':
          return canEventBeCancelled(eventInstance);
        case 'cancel-waitinglist':
          if (participates &&
            isPending &&
            !isPendingWithReservationSale
          ) {
            return canEventBeCancelled(eventInstance);
          }
          break;
        case 'reject':
          return canEventBeCancelled(eventInstance);
        default:
      }
      return false;
    }

/*    function threeHoursCancellation(eventInstance) {
      return vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60);
    }*/

    function canEventBeCancelled(eventInstance) {
      return vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 1 * 60 * 60);
    }

    function showCalendar(status) {
      vm.calendarIsVisible = angular.isDefined(status) ? status : true;
    }

    function showUserContextSwitch() {
      return ($rootScope.environment ? $rootScope.environment.multisite : false) && vm.siteSwitchingEnabled;
    }

    function initHistory() {
      var parameters = {
        limit: 99
      };

      vm.participationHistory = [];
      parameters['filter[]'] = ['contact.id,' + vm.customerContact.contact.id];
      parameters['filter[]'].push('eventParticipantStatus.code,ATTENDED');
      parameters['filter[]'].push('site.id,' + $rootScope.environment.siteId);
      $log.debug(parameters);

      RestUtilsFactory.getFullList(EventParticipantFactory, parameters).then(function (participationHistory) {
        $log.debug(participationHistory);
        vm.participationHistory = participationHistory;
      });
    }

    function initWebshop() {
      vm.products = [];
    }

    function getProductByTypeLabel(label, subcategory) {
      vm.products = [];
      if (angular.isDefined(label) && angular.isDefined(subcategory)) {
        return ProductFactory.getProductsByCategories([this.productMainCategory, label, subcategory]).then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }

      if (label) {
        ProductFactory.getProductsByCategories([this.productMainCategory, label]).then(function (result) {
          vm.products = result;
          vm.allProducts = result;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }
    }

    function initWebshopCart() {
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      if (vm.enableCustomersCoins && vm.customerCoin) {
        vm.cartTotals -= ShoppingCartService.getTotalCoinsDiscount();
      }
    }

    function openSidebar(event, state) {
      event.preventDefault();
      if (state) {
        vm.openNav = 'width: 100%; max-width: 500px; margin-left: 500px';
      } else {
        vm.openNav = 'width: 0px; max-width: 0px; margin-left: 0px';
      }
    }

    vm.buyProduct = function (product) {
      return ProductFactory.buyProduct(product).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.addToCart = function (product, inputQuantity) {
      ShoppingCartService.add(product, inputQuantity);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      if (vm.enableCustomersCoins && vm.customerCoin) {
        ShoppingCartService.setCustomerCoin(vm.customerCoin);
        vm.amountOfProductsWithJournal = ShoppingCartService.getAmountProductsWithJournal();
      }
      vm.maxProductsNumber -= inputQuantity;
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.add_to_cart_success');
    };

    vm.getTicketsCount = function () {
      return ShoppingCartService.getTotalQuantity();
    };

    vm.addIndividualTicketToCart = function (product, inputQuantity) {
      ShoppingCartService.addOrRemoveIndividual(product, inputQuantity);
      vm.cartIndividualItems = ShoppingCartService.getIndividual();
      vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
      vm.individualTicketsCount = ShoppingCartService.countIndividual();
    };

    vm.removeFromCart = function (product) {
      vm.maxProductsNumber += ShoppingCartService.getProduct(product).quantity;
      ShoppingCartService.remove(product);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      if (vm.enableCustomersCoins && vm.customerCoin) {
        if (vm.cartTotals > 0) {
          vm.cartTotals -= ShoppingCartService.getTotalCoinsDiscount();
        }
        vm.amountOfProductsWithJournal = ShoppingCartService.getAmountProductsWithJournal();
      }
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.remove_from_cart_success');
    };

    vm.checkoutShoppingCart = function () {
      return ProductFactory.checkoutShoppingCart(ShoppingCartService.checkout()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          if (angular.isDefined(response.url)) {
            $window.location.href = response.url;
          } else {
            vm.saleId = response.saleId;
            vm.finalizeSale(true);
          }
        });
      });
    };

    vm.checkoutIndividualTicketsShoppingCart = function () {
      ShoppingCartService.setGuest(vm.guest);
      if (angular.isDefined(vm.subscriptionId)) {
        ShoppingCartService.setSubscriptionId(vm.subscriptionId);
      }
      return ProductFactory.checkoutShoppingCart(ShoppingCartService.checkoutForIndividualTickets()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          if (angular.isDefined(response.url)) {
            $window.location.href = response.url;
          } else {
            vm.saleId = response.saleId;
            vm.finalizeSale(true);
          }
        });
      });
    };

    vm.checkoutGiftProductsShoppingCart = function () {
      ShoppingCartService.setGuest(vm.guest);
      ShoppingCartService.setDeliveryRequested(vm.needDelivery);
      ShoppingCartService.setDeliveryAddress(vm.deliveryAddress);
      return ProductFactory.checkoutShoppingCart(ShoppingCartService.checkoutGiftProductsCart()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale, true).then(function (response) {
          if (angular.isDefined(response.url)) {
            $window.location.href = response.url;
          } else {
            vm.saleId = response.saleId;
            vm.finalizeSale(true);
          }
        });
      });
    };

    vm.renewJournal = function (product) {
      if (vm.renewJournalIsOpen) {
        return Promise.reject();
      }
      vm.renewJournalModalInstance = $modal.open({
        templateUrl: 'kiosk/views/renew_journal.modal.tpl.html',
        controller: function () {
          this.product = product;

          this.cancel = function () {
            vm.renewJournalModalInstance.dismiss();
          };

          this.renew = function () {
            vm.renewJournalModalInstance.close();
          };
        },
        controllerAs: 'renewJournalCtrl'
      });
      vm.renewJournalIsOpen = true;

      return vm.renewJournalModalInstance.result.then(function () {
        vm.renewJournalIsOpen = false;
        return vm.buyProduct(product);
      }, function () {
        vm.renewJournalIsOpen = false;
        return Promise.reject();
      });
    };

    vm.switchUserLocale = function () {
      $modal.open({
        size: 'sm',
        templateUrl: 'kiosk/views/switch-user-locale.modal.tpl.html',
        controller: ['LOCALES', function (LOCALES) {
          this.locales = LOCALES.locales;
        }],
        controllerAs: 'switchUserLocaleCtrl'
      })
        .result
        // handle close
        .then(function (locale) {
          LocaleService.setLocale(locale, true);
        });
    };

    vm.switchUserContext = function () {
      vm.userContextSwitchModal = $modal.open({
        size: 'lg',
        templateUrl: 'kiosk/views/switch-user-context.modal.tpl.html',
        controller: 'UserContextSwitchCtrl',
        controllerAs: 'switchUserContextCtrl',
        resolve: {
          sites: function () {
            return vm.environments.filter(function (site) {
              return (site.multisite && site.siteId !== $rootScope.environment.siteId);
            }).sort();
          }
        }
      }).result.then(function (result) {
        var environment = vm.environments.filter(function (e) {
          return e.siteId === result.site.id;
        })[0];

        ShoppingCartService.clear();

        vm.loadConfig(environment.index, true).then(function () {
          fetchUser().then(function () {
            return $state.reload();
          });
        });
      });
    };

    vm.confirmationModal = function () {
      const headText = 'Delete Account';
      const bodyText = 'Are you sure you want to delete your account?';

      UtilsFactory.showConfirmationModal(headText, bodyText, function (resolve) {
        if (resolve) {
          UserMeFactory.one().get()
            .then(function (user) {
              Restangular.service('customer').one(user.id).one('delete').remove().then(function () {
                console.log('user deleted');
              });
            }).then(function (){
              logout();
          });
        }
      });
    }

    function updateCategories(category) {
      category.displayFlag = !category.displayFlag;
      vm.fitnessEvents.forEach(function (event) {
        event.fitnessEvent.eventCategories.forEach(function (eventCategory) {
          if (eventCategory.code === category.code) {
            eventCategory.displayFlag = category.displayFlag;
          }
        });
      });
      return category;
    }

    function loadConfig(selectedIndex, returnPromise) {
      var promise;

      vm.selectedBackend = selectedIndex;
      $localStorage.selectedBackend = vm.selectedBackend;
      $localStorage.selectedEnvironment = vm.environments[$localStorage.selectedBackend];
      promise = $timeout(function () {
        $rootScope.environment = vm.environments[$localStorage.selectedBackend];
      });

      if (angular.isDefined(returnPromise) && returnPromise) {
        return promise;
      }

      if (!UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $window.location.href = '/login';
        //$location.path('login');
      } else {
        $location.path($localStorage.redirectLink);
      }
    }

    function back() {
      $state.go('kiosk');
    }

    function subscribeButtonDisabled(eventInstance) {
      var availableCredits = 0;
      angular.forEach(eventInstance.eligibleJournalTypeIds, function (eligibleJournalType) {
        angular.forEach(vm.journalTypes[eligibleJournalType], function (journalId) {
          if (vm.journals[journalId].credits !== null) {
            availableCredits += vm.journals[journalId].credits;
          }
        });
      });

      return (vm.registeredEvents >= availableCredits || vm.contactFitnessCredits <= 0);
    }

    function isDisplayAttentionMessage(eventInstance) {
      var eventStartTimeInSeconds = vm.dateToSeconds(eventInstance.startsAt);
      return (vm.now.seconds > (eventStartTimeInSeconds - (eventInstance.reservationHours * 60 * 60)) &&
        vm.now.seconds < eventStartTimeInSeconds && (!eventInstance.userStatus || eventInstance.userStatus.code === 'REJECTED'));
    }

    function changeLocale(locale, returnPromise) {
      if (angular.isDefined(returnPromise) && returnPromise) {
        return new Promise(function (resolve) {
          resolve(LocaleService.setLocale(locale, true));
        });
      }

      LocaleService.setLocale(locale, false);
    }

    function filterByEntrances(entrance, categoryLabel) {
      var productLabel = '';
      if (vm.currentLocale === 'FR_FR') {
        if (entrance === '(Inwoners)') {
          entrance = '(Habitants)';
        } else if (entrance === '(Niet-inwoners)') {
          entrance = '(Non Habitants)';
        }
      }
      vm.getProductList(categoryLabel).then(function (productsResult) {
        entrance = entrance.toLowerCase();
        if (entrance === '(inwoners)' || entrance === '(habitants)') {
          vm.products = [];
          productsResult.forEach(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            if (!(productLabel.split('(niet-inwoners)').length > 1 ||
              productLabel.split('(niet inwoners)').length > 1 ||
              productLabel.split('(non habitants)').length > 1 ||
              productLabel.split('(non-habitants)').length > 1)) {
              vm.products.push(product);
            }
          });
        } else {
          vm.products = productsResult.filter(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            return productLabel.split(entrance).length > 1 || productLabel.split('(niet inwoners)').length > 1;
          });
        }
      });
    }

    function getProductList(label) {
      var defer = $q.defer();
      defer.resolve(vm.allProducts ? vm.allProducts : ProductFactory.getProductsByLabelType(label));
      return defer.promise;
    }

    vm.showWebshopCartMenuItem = function () {
      return Object.keys(vm.cartItems).length > 0;
    };

    vm.isNewCustomer = function () {
      return !UtilsFactory.isNotEmpty(vm.fitnessEvents) && !UtilsFactory.isNotEmpty(vm.journals);
    };

    vm.openIndividualTicketsDatePicker = function () {
      vm.individualTicketsDatePickerIsOpened = true;
    };

    vm.openEventsDatePicker = function () {
      vm.eventsDatePickerIsOpened = true;
    };

    vm.activitysFromEnv = function () {
      if (vm.isGroupLessonTickets) {
        return vm.environments[$localStorage.selectedBackend].groupLessonActivityTypeForIndividualEvents;
      }

      if ($localStorage.activityName === 'wellness') {
        if ($localStorage.indvidualTicketsOption === 1) {
          return ['Badenhuis'];
        }

        return ['Hammam'];
      }

      if ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption !== 3) {
        return vm.activityTypesBasedOnProduct;
      }

      return vm.environments[$localStorage.selectedBackend].activityTypeForIndividualEvents;
    };

    function previousPurchaseStep() {

     //exception ice skating
      if ($localStorage.activityName === 'ice skating' && $state.current.name !== 'kiosk.user') {
        if (vm.currentPurchaseStep === 3) {
          ShoppingCartService.clear();
          vm.purchaseProducts = [];
          vm.currentPurchaseStep = 1;
          return;
        }
      }

      if ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption !== 3) {
        if (vm.currentPurchaseStep === 2) {
          ShoppingCartService.clear();
          vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
          vm.purchaseProducts = vm.divingProducts;
          vm.currentPurchaseStep = 1;
          return;
        }
      }

      if (($localStorage.activityName === 'swimming' || ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption === 3)) && $state.current.name !== 'kiosk.user') {
        if (vm.currentPurchaseStep === 3) {
          vm.currentPurchaseStep = 1;
        } else {
          vm.currentPurchaseStep--;
        }
      } else {
        vm.currentPurchaseStep--;
      }
    }

    function nextPurchaseStep() {
      // yet another hack

      // ice skating exception
      if (vm.iceSkatingActive) {
        if (vm.currentPurchaseStep === 1) {
          vm.currentPurchaseStep = '1-rental';
          vm.purchaseProducts = vm.iceSkatingRentalProducts;
          return;
        }

        if (vm.currentPurchaseStep == '1-rental') {
          vm.currentPurchaseStep = 2;
          return;
        }
      }

      //special case for diving
      if ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption !== 3) {
        if (vm.currentPurchaseStep === 1) {
          if (ShoppingCartService.getIndividualProductsJournalTypes().length > 1) {
            ToastrNotificationService.showTranslatedNotification('warning', 'kiosk.webshop', 'product.journal_type_error');
            return;
          }
          //get activity types based on products
          vm.getActivityTypesBasedOnProducts();
          vm.currentPurchaseStep = $localStorage.indvidualTicketsOption === 2 ? '1-rental' : 2;
          if ($localStorage.indvidualTicketsOption === 2) {
            vm.purchaseProducts = vm.divingRentalProducts;
          }
          return;
        }

        if (vm.currentPurchaseStep == '1-rental') {
          vm.currentPurchaseStep = 2;
          return;
        }
      }

      // see #79165
      if (($localStorage.activityName === 'swimming' || ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption === 3)) && $state.current.name !== 'kiosk.user') {
        if (vm.currentPurchaseStep === 1) {
          vm.currentPurchaseStep = 3;
        } else {
          vm.currentPurchaseStep++;
        }
      } else {
        vm.currentPurchaseStep++;
      }
    }

    function getProductListByCategories(category, subcategory) {
      vm.purchaseProducts = [];
      vm.activePurchaseResidentButton = subcategory;
      //for ice skating we need extra filter, not search products there
      if (vm.iceSkatingActive) {
        vm.iceSkatingSubCategory = subcategory;
      }
      if (vm.sportProducts) {
        vm.sportProductsSubCategory = subcategory;
        return;
      }
      if (category && subcategory) {
        ProductFactory.getProductsByCategories([vm.productMainCategory, category, subcategory]).then(function (products) {
          vm.purchaseProducts = products;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.purchaseProducts = products;
        });
      }
    }

    function getSportProductsByCategories(categories) {
      var mainCategories = [vm.productMainCategory];
      vm.purchaseProducts = [];
      if (vm.sportProductsSubCategory) {
        // check mark
        vm.activeSportButton = categories[1];
        mainCategories.push(vm.sportProductsSubCategory);
      }
      ProductFactory.getProductsByCategories(mainCategories.concat(categories)).then(function (products) {
        vm.purchaseProducts = products;
      });
    }

    function getProductsByCategories(categories) {
      var mainCategories = [vm.productMainCategory];
      vm.purchaseProducts = [];

      console.log('getProductsByCategories');
      console.log(categories);

      ProductFactory.getProductsByCategories(mainCategories.concat(categories)).then(function (products) {
        vm.purchaseProducts = products;
      });
    }

    function getEventsForIndividualTickets(returnOnly) {
      return EventFactory.one('instances')
        .one('individual_upcoming')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.individualTicketsDate, 'yyyy-MM-dd'),
          'filter[]': 'label,LIKE ' + vm.activityTypeForIndividualEvents
        }).then(function (result) {
          var eventInstances = result.filter(function (eventInstance) {
            return (!vm.selectedEventInstanceForEdit ||
              (vm.selectedEventInstanceForEdit.id !== eventInstance.id && vm.selectedEventInstanceForEdit.reservationCount <= eventInstance.numberOfVacancies)
            );
          });
          if (returnOnly === true) {
            return eventInstances;
          }
          vm.eventsForIndividualTickets = eventInstances;
          vm.noEventsWithAvailablePlacesForIndividual = true;
          vm.eventsForIndividualTickets.forEach(function (eventInstance) {
            if (eventInstance.numberOfVacancies > 0 && vm.now.seconds < vm.dateToSeconds(eventInstance.startsAt)) {
              vm.noEventsWithAvailablePlacesForIndividual = false;
            }
          });
          vm.searchEventsDone = true;
        });
    }

    function selectEventInstance(eventInstance) {
      ShoppingCartService.setReservationEvent(eventInstance);
      vm.selectedEventInstanceForIndividualTickets = eventInstance;
      vm.currentPurchaseStep++;
    }

    function checkPromocode(individual) {
      var productsData = angular.isDefined(individual) ? ShoppingCartService.getIndividualProductsData() : ShoppingCartService.getProductsData();
      if (vm.promocodeFieldValue !== '') {
        PromocodeFactory.checkPromocode(vm.promocodeFieldValue, vm.customer.id, productsData).then(function (result) {
          vm.promocodeValid = result.valid;
          vm.promocodeChecked = true;
          vm.setPromocode();
          if (result.valid) {
            vm.setPromocode(result.promocode, result.value, result.type, result.products.map(function (item) {
              return item.id;
            }));
          }
        });
      }
    }

    function setPromocode(promocode, value, type, products) {
      ShoppingCartService.setPromocode(promocode);
      ShoppingCartService.setPromocodeValue(value);
      ShoppingCartService.setPromocodeType(type);
      ShoppingCartService.setPromocodeProducts(products);
      vm.promocodeValue = value;
      vm.promocodeType = type;
      vm.cartTotals = ShoppingCartService.getCartTotalsWithPromocode();
      vm.cartTotalDiscount = ShoppingCartService.getCartTotalDiscount();
      vm.cartIndividualTotalDiscount = ShoppingCartService.getCartIndividualTotalDiscount();
      vm.cartIndividualTotals = ShoppingCartService.getCartIndividualTotalsWithPromocode();
    }

    function getEventsForCustomer() {
      var loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });
      return EventFactory.one('instances')
        .one('upcoming_for_customer')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.eventsDate, 'yyyy-MM-dd')
        }).then(function (result) {
          var eventCategoriesTranslatedLabels = {};
          vm.fitnessEvents = result;

          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            // calculate calendar data
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(vm.calendar[eventInstance.date])) {
              vm.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            if (angular.isDefined(eventInstance.userStatus) && (eventInstance.userStatus.code === 'ACCEPTED' ||
              eventInstance.userStatus.code === 'PENDING' ||
              eventInstance.userStatus.code === 'TENTATIVE')) {
              vm.registeredEvents++;
            }

            eventInstance.eligibleJournalTypeIds = [];
            eventInstance.classes = [];
            eventInstance.categories = {};
            eventInstance.calendar = {};

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategoriesTranslatedLabels[eventCategory.id] = eventCategory.translatedLabel;
            });

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategory.displayFlag = true;

              // categories
              if (angular.isUndefined(vm.categories[eventCategory.code])) {
                vm.categories[eventCategory.code] = {
                  code: eventCategory.code,
                  label: eventCategory.label,
                  translatedLabel: eventCategoriesTranslatedLabels[eventCategory.id]
                };
              }
              eventInstance.classes.push(eventCategory.code);
            });
            // override classes with fitness course color tag if set
            if (angular.isDefined(eventInstance.fitnessCourses) && angular.isDefined(eventInstance.fitnessCourses[0]) &&
              eventInstance.fitnessCourses[0].tags && angular.isDefined(eventInstance.fitnessCourses[0].tags[0]) &&
              angular.isDefined(eventInstance.fitnessCourses[0].tags[0].id)
            ) {
              eventInstance.classes = [eventInstance.fitnessCourses[0].tags[0].id];
            }
            // calendar
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(eventInstance.calendar[eventInstance.date])) {
              eventInstance.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            // collect eligible journal types
            angular.forEach(eventInstance.fitnessCourses, function (currentFitnessCourse) {
              angular.forEach(currentFitnessCourse.journalTypes, function (currentJournalType) {
                if (eventInstance.eligibleJournalTypeIds.indexOf(currentJournalType.id) === -1) {
                  eventInstance.eligibleJournalTypeIds.push(currentJournalType.id);
                }
              });
            });

            // calculate latest confirmation date
            eventInstance.maxConfirmationDate = moment(eventInstance.startsAt).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
          });
          loadingModal.close();
          vm.fitnessEventsAreLoading = false;
          vm.needReloadEvents = true;
        });
    }

    function getReservationsForCustomer() {
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.myFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            vm.myFitnessEvents.push(eventInstance);
          });
        });
    }

    function getEditableReservationsForCustomer() {
      vm.fitnessEventsAreLoading = true;
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.editableFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            if (vm.showButton('reject', eventInstance)) {
              eventInstance.reservationCount = vm.getRejects(eventInstance).length;
              vm.editableFitnessEvents.push(eventInstance);
            }
          });
        }).finally(function () {
          vm.fitnessEventsAreLoading = false;
        });
    }

    function editEventInstance(eventInstance) {
      vm.eventsForIndividualTickets = [];
      vm.activityTypeForIndividualEvents = eventInstance.label;
      vm.searchEventsDone = false;
      vm.selectedEventInstanceForEdit = eventInstance;
    }

    function displayDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = true;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }

    function closeDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = false;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }
    function changeEventInstance(newEventInstance) {
      var participations = [],
          promiseQueue = [];

      if (!vm.selectedEventInstanceForEdit) {
        return Promise.reject();
      }

      participations = vm.getRejects(vm.selectedEventInstanceForEdit);
      if (participations.length === 0) {
        return Promise.reject();
      }

      return vm.getEventsForIndividualTickets(true).then(function (eventInstances) {
        var placesAvailable = false;
        // make sure places are still available
        eventInstances.forEach(function (eventInstance) {
          if (eventInstance.id === newEventInstance.id && eventInstance.numberOfVacancies >= participations.length) {
            placesAvailable = true;
          }
        });
        if (placesAvailable === false) {
          return Promise.reject();
        }
        participations.forEach(function (participation) {
          promiseQueue.push(EventParticipantFactory.one(participation.id).patch({eventInstance: newEventInstance.id}));
        });
        return Promise.all(promiseQueue).finally(function () {
          $rootScope.guestEventUpdated = true;
          return $state.reload();
        });
      });
    }

    function handleSiteClick(environment) {
      if (vm.activityName === 'facilityPlanning' && environment.name === 'Hoge Wal' && environment.hasFacilityPlanning) {
        $window.open('https://sportinfrastructuur.evergem.be/', '_blank');
      } else {
        vm.actionSelection(environment.index, environment.name);
      }
    }

    vm.actionSelection = function (index, name) {
      if (vm.actionSelectionIsOpen) {
        return Promise.reject();
      }
      vm.actionSelectionModalInstance = $modal.open({
        templateUrl: 'kiosk/views/action_selection.modal.tpl.html?v' + Date.now(),
        controller: function () {
          this.selectedSiteIndex = index;
          this.selectedNameSite = name;
          $localStorage.selectedBackend = index;
          this.getTranslateText = getTranslateText;
          this.isDisplayButton = isDisplayButton;
          this.displayFirstLevelMenu = true;
          this.nextStep = function () {
            this.displayFirstLevelMenu = false;
          };
          this.prevStep = function () {
            this.displayFirstLevelMenu = true;
          };
          this.registerAsGuest = function (siteIndex, isGuest, isGroupLesson, option) {
            $localStorage.indvidualTicketsOption = option;
            return vm.logout(true).finally(function () {
              vm.loadConfig(siteIndex, true).then(function () {
                var url = '/register_guest_user/' + isGuest + '/' + isGroupLesson;
                // $window.location.href = url;
                // setTimeout(function(){document.location.href = url;},100);
                $window.location.assign(url);
              });
            });
          };

          this.loadSiteConfig = function (siteIndex) {
            return new Promise(function () {
              return vm.loadConfig(siteIndex);
            });
          };

          this.close = function () {
            vm.actionSelectionModalInstance.dismiss();
            vm.actionSelectionIsOpen = false;
          };

          this.goToOtherSite = function(buttonName) {
            var url = SettingsService.getPublicSetting('onlineReservation.' + vm.activityName + buttonName, false);
            $window.open(url, '_blank');
          };

          this.getFooterText = function () {
            switch (vm.activityName) {
              case 'group lessons':
                return getValueFromSetting('onlineReservation.selectionModalGrouplessonsFooter');
              case 'swimming':
                return getValueFromSetting('onlineReservation.selectionModalSwimmingFooter');
              case 'fitness':
                return getValueFromSetting('onlineReservation.selectionModalFitnessFooter');
              case 'wellness':
                return getValueFromSetting('onlineReservation.selectionModalWellnessFooter');
              case 'ice skating':
                return getValueFromSetting('onlineReservation.selectionModalIceSkatingFooter');
              case 'diving':
                return getValueFromSetting('onlineReservation.selectionModalDivingFooter');
              case 'squash':
                return getValueFromSetting('onlineReservation.selectionModalSquashFooter');
              default:
                return '';
            }
          };

          this.getHeaderText = function () {
            switch (vm.activityName) {
              case 'group lessons':
                return getValueFromSetting('onlineReservation.selectionModalGrouplessonsHeader');
              case 'swimming':
                return this.getSwimmingHeaderText(this.selectedNameSite);
              case 'fitness':
                return getValueFromSetting('onlineReservation.selectionModalFitnessHeader');
              case 'wellness':
                return getValueFromSetting('onlineReservation.selectionModalWellnessHeader');
              case 'ice skating':
                return getValueFromSetting('onlineReservation.selectionModalIceSkatingHeader');
              case 'diving':
                return getValueFromSetting('onlineReservation.selectionModalDivingHeader');
              default:
                return '';
            }
          };

          this.getSwimmingHeaderText = function (selectedNameSite) {
            var valueSetting;
            this.isSelectedSiteNameGroot = false;
            if (selectedNameSite === 'Groot Schijn') {
              valueSetting = getValueFromSetting('onlineReservation.selectionModalSwimmingGrootHeader');
              this.isSelectedSiteNameGroot = angular.isDefined(valueSetting) && valueSetting !== '';
            }
            return this.isSelectedSiteNameGroot ? valueSetting : getValueFromSetting('onlineReservation.selectionModalSwimmingHeader');
          };

          this.getTitleText = function () {
            switch (vm.activityName) {
              case 'group lessons':
                return getValueFromSetting('onlineReservation.selectionModalGrouplessonsTitle');
              case 'swimming':
                return getValueFromSetting('onlineReservation.selectionModalSwimmingTitle');
              case 'fitness':
                return getValueFromSetting('onlineReservation.selectionModalFitnessTitle');
              case 'wellness':
                return getValueFromSetting('onlineReservation.selectionModalWellnessTitle');
              case 'ice skating':
                return getValueFromSetting('onlineReservation.selectionModalIceSkatingTitle');
              case 'diving':
                return getValueFromSetting('onlineReservation.selectionModalDivingTitle');
              default:
                return '';
            }
          };
        },
        controllerAs: 'selectionCtrl'
      });

      function getValueFromSetting(settingName) {
        return (angular.fromJson(SettingsService.getPublicSetting(settingName, false)) !== false) ?
          angular.fromJson(SettingsService.getPublicSetting(settingName, false)).text[vm.currentLocale.split('_')[0]] :
          '';
      }
      vm.actionSelectionIsOpen = true;

      return vm.actionSelectionModalInstance.result.then(function () {
        vm.actionSelectionIsOpen = false;
      }, function () {
        vm.actionSelectionIsOpen = false;
        return Promise.reject();
      });
    };

    function addDeliveryProductToCart(quantity) {
      var deliveryProductId = SettingsService.getPublicSetting('settings.deliveryProductId', undefined);

      if (angular.isDefined(deliveryProductId)) {
        ProductFactory.getProductById(deliveryProductId).then(function (product) {
          ShoppingCartService.addOrRemoveIndividual(product, quantity);
          vm.cartIndividualItems = ShoppingCartService.getIndividual();
          vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
          vm.individualTicketsCount = ShoppingCartService.countIndividual();
        });
      }
    }

    function setTicketCategories(component) {
      vm.purchaseProducts = [];
      vm.activePurchaseResidentButton = null;
      vm.eventsForIndividualTickets = [];
      vm.noEventsWithAvailablePlacesForIndividual = false;
      vm.productCategoryChoosed = false;
      switch (component) {
        case 'GROUPLESSONINDIVIDUALTICKETS':
          vm.productMainCategory = 'Groepslessen';
          vm.activityTypeForIndividualEvents = 'Groepslessen';
          vm.isGroupLessonTickets = true;
          break;
        case 'ICESKATING':
          vm.productMainCategory = 'Schaatsbaan';
          vm.isGroupLessonTickets = false;
          vm.iceSkatingActive = true;
          break;
        case 'SQUASH':
          vm.productMainCategory = 'squash';
          vm.isGroupLessonTickets = false;
          vm.iceSkatingActive = true;
          break;
        default:
          vm.productMainCategory = 'Zwembad';
          vm.isGroupLessonTickets = false;
          //@todo fix this hardcoded check
          if ($localStorage.selectedEnvironment.siteId == "d2734711-3a80-11e9-8ed3-525400590f8b") {
            vm.sportProducts = true;
          }
          break;
      }
    }

    function setWebshopProductCategory(productCategory) {
      vm.productCategoryChoosed = true;
      vm.productMainCategory = productCategory;
      vm.webshopGroupLesson = productCategory === 'Groepslessen';
    }

    function getWelcomeText() {
      switch (vm.currentLocale) {
        case 'NL_BE':
          return SettingsService.getPublicSetting('kiosk.landingPageWelcomeTextNL', false);
        case 'EN_US':
          return SettingsService.getPublicSetting('kiosk.landingPageWelcomeTextEN', false);
        case 'FR_FR':
          return SettingsService.getPublicSetting('kiosk.landingPageWelcomeTextFR', false);
        default:
          return false;
      }
    }

    function getTranslateText(buttonName) {
      var setting;

      if (vm.currentLocale === 'NL_BE' || vm.currentLocale === 'EN_US' || vm.currentLocale === 'FR_FR') {
        if (isDisplayButton(buttonName)) {
          setting = getSettingViaButtonName(buttonName);

          if (setting) {
            return setting.text[vm.currentLocale.split('_')[0]];
          }
        }
      }

      return false;
    }

    function isDisplayButton(buttonName) {
      var setting = getSettingViaButtonName(buttonName);

      if (setting) {
        return setting.enable;
      }

      return false;
    }

    function getSettingViaButtonName(buttonName) {
      var fixButtonName, settingViaButtonName;
      switch (vm.activityName) {
        case 'group lessons':
          fixButtonName = 'grouplessons' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'swimming':
          fixButtonName = 'swimming' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'fitness':
          fixButtonName = 'fitness' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'wellness':
          fixButtonName = 'wellness' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'ice skating':
          fixButtonName = 'iceSkating' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'diving':
          fixButtonName = 'diving' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        case 'squash':
          fixButtonName = 'squash' + buttonName;
          settingViaButtonName = angular.fromJson(SettingsService.getPublicSetting('onlineReservation.' + fixButtonName, false));
          return settingViaButtonName;
        default:
          break;
      }
    }

    function showResidentFilter() {
      console.log($localStorage.activityName);

      if (vm.iceSkatingActive || $localStorage.activityName === 'diving') {
        return false;
      }

      if ((vm.currentPurchaseStep === 1 && vm.isGroupLesson !== 'true' && $localStorage.activityName !== 'wellness' && !vm.iceSkatingSubCategory) ||
        (vm.currentPurchaseStep === 1 && vm.isGroupLesson !== 'true' && vm.iceSkatingActive && vm.iceSkatingSubCategory)) {
        return true;
      }

      return false;
    }

    function showResidentFilterIceSkating() {
      if (vm.currentPurchaseStep === 1 && vm.iceSkatingActive) {
        return true;
      }

      return false;
    }

    function showRentalFilter() {
      return vm.currentPurchaseStep == '1-rental';
    }

    function showSportFilter() {
      return vm.currentPurchaseStep === 1 && vm.isGroupLesson !== 'true' && vm.sportProducts;
    }

    function showPruchaseStep(step) {
      if (step === 2 && ($localStorage.activityName === 'swimming' || ($localStorage.activityName === 'diving' && $localStorage.indvidualTicketsOption === 3))) {
        return false;
      }

      if (step == '1-rental' && ($localStorage.activityName != 'ice skating' && ($localStorage.activityName !== 'diving' || $localStorage.indvidualTicketsOption !== 2))) {
        return false;
      }

      return true;
    }

    function toggleClass(imgName) {
      return (vm.activeImg === imgName || vm.activeImg === null) ? 'environment__filter-list-img--colored' : 'environment__filter-list-img--grey';
    }

    function switchActiveImg(imgName) {
      vm.activeImg = imgName;
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function buyGiftProducts(siteIndex, isGuest, isGroupLesson, option) {
      if (angular.isDefined(option)) {
        $localStorage.indvidualTicketsOption = option;
      }
      return vm.logout(true).finally(function () {
        vm.loadConfig(siteIndex, true).then(function () {
          $window.location.href = '/register_guest_user/' + isGuest + '/' + isGroupLesson;
        });
      });
    }

    function chooseSiteForGiftBox() {
      $window.location.href = '/choose_site?giftBox=true';
    }

    function loginCustomerForPayment(data) {
      var decodedData = angular.fromJson(atob(data));
      return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/login-customer')
        .customPOST({
          site: decodedData.site,
          customer: decodedData.customerId
        }).then(function (response) {
          $log.debug('KIOSK: Customer login successful', response);

          OAuthToken.setToken(response);
          SettingsService.reloadPublicSettings().then(function () {
            vm.subscriptionJournalProduct = decodedData.productId;
          });
          return fetchUser().then(function (customer) {
            return vm.changeLocale(decodedData.locale, true).then(function () {
              return $timeout(function () {
                $state.go('kiosk.swimmingschool-payment_process_payment', {customerId: customer.id, subscriptionId: decodedData.subscriptionId, productId: decodedData.productId});
              }, 1000);
            });
          });
        }, function (errorResponse) {
          $log.debug('KIOSK: Customer login failed' + errorResponse);
        });
    }

    function loadSiteConfigForCustomer(data) {
      var decodedData = angular.fromJson(atob(data)),
          site = vm.environments.filter(function (element) {
              return element.siteId === decodedData.site && element.siteName === decodedData.siteName;
          });
      return vm.loadConfig(site[0].index, true);
    }

    function addSubscriptionJournalProductToCart(productId, quantity) {
      if (angular.isDefined(productId)) {
        ProductFactory.getProductById(productId).then(function (product) {
          ShoppingCartService.addOrRemoveIndividual(product, quantity);
          vm.cartIndividualItems = ShoppingCartService.getIndividual();
          vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
          vm.individualTicketsCount = ShoppingCartService.countIndividual();
        });
      }
    }

    function loadSubscriptionInfo(subscriptionId) {
      return SubscriptionFactory.getSubscriptionById(subscriptionId).then(function (response) {
          vm.swimmingCourseLessonLabel = response.swimCourseSet.label;
          vm.swimmingCourseLevelLabel = response.swimCourseLevel.label;
        }, function (errorResponse) {
          $log.debug('KIOSK: Load subscription failed' + errorResponse);
        });
    }

    vm.addQuantity = function(index) {
      if(vm.purchaseProducts[index].inputQuantity >= 0) {
        return vm.purchaseProducts[index].inputQuantity + 1;
      }
      return 0;
    }

    vm.minQuantity = function(index) {
      if(vm.purchaseProducts[index].inputQuantity > 0) {
        return vm.purchaseProducts[index].inputQuantity - 1;
      }
      return 0;
    }

    vm.setQuantity = function(value) {
      if(value > 0) {
        return value;
      }
      return 0;
    }


    function getActivityTypesBasedOnProducts() {
      var productsData = ShoppingCartService.getIndividualProductsData();
      return EventFactory.one('activity_types_by_products').get({products: productsData}).then(function (result) {
        vm.activityTypesBasedOnProduct= angular.fromJson(result);
      })
    }

    function loadCustomerCoinsInformation() {
      CustomerFactory.one(vm.customer.id).one('coins').get({limit: 1, offset: 0 , sort: 'createdAt,DESC'}).then(function (result) {
        if (angular.isDefined(result.count) && result.count > 0) {
          vm.customerCoin = angular.isDefined(result.results) && angular.isDefined(result.results[0]) ? result.results[0] : null;
        }
      })
    }
  }
}());
