(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EndpointFactory
   *
   * @description
   *
   */
  angular
    .module('utils')
    .factory('EndpointFactory', EndpointFactory);

  function EndpointFactory(ENVIRONMENTS) {
    var vm = this;
    vm.getEndpointBySiteId = getEndpointBySiteId;
    vm.getEndpointBySiteName = getEndpointBySiteName;

    function getEndpointBySiteId(siteId) {
      var result = 'default';
      ENVIRONMENTS.forEach(function (site) {
        if (siteId === site.siteId) {
          result = site.baseUrl + '/api/';
        }
      });
      return result;
    }

    function getEndpointBySiteName(siteName) {
      var result = 'default';
      ENVIRONMENTS.forEach(function (site) {
        if (siteName === site.name) {
          result = site.baseUrl + '/api/';
        }
      });
      return result;
    }
    return vm;
  }
}());
