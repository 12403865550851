(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:MultipleSubscribeCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('MultipleSubscribeEditCtrl', MultipleSubscribeEditCtrl);

  function MultipleSubscribeEditCtrl(
    $modalInstance,
    FitnessEventParticipationService,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    EventFactory
  ) {
    var vm = this;
    vm.close = close;
    vm.subscribe = subscribe;
    vm.cancelParticipation = cancelParticipation;
    vm.buttonsData = [];
    vm.eventId = $modalInstance.eventInstance.eventId;
    vm.eventInstanceId = $modalInstance.eventInstance.id;
    vm.contactId = $modalInstance.customerContact.contact.id;
    vm.maxCountOfButtonsForSubscribeAndReject = 4;
    //call to fetch customer credits
    vm.customerCredits = $modalInstance.availableCredits;

    loadData($modalInstance.eventInstance);

    function getEventInstance() {
      return EventFactory.one(vm.eventId)
        .one('instances')
        .one(vm.eventInstanceId)
        .one('contact')
        .one(vm.contactId)
        .get();
    }

    function loadData(eventInstance) {
      vm.buttonsData = [];
      eventInstance.eventParticipants.forEach(function (participant) {
        if (participant.eventParticipantStatus.code === 'ACCEPTED' && participant.eventParticipantType.code === 'PARTICIPANT' && $modalInstance.customerContact.contact.id === participant.contact.id) {
          vm.buttonsData.push({ type: 'reject', parcipantId: participant.id });
        }
      });
      addToDataSubscribeButton(vm.buttonsData);
    }

    function reloadData() {
      return Promise.resolve(new Promise(function (res) {
        return res(getEventInstance());
      })).then(function (result) {
        loadData(result);
      });
    }

    function addToDataSubscribeButton(buttonDataArray) {
      var i = 0, countSubscribeButtons = vm.maxCountOfButtonsForSubscribeAndReject - buttonDataArray.length;
      if (angular.isDefined(vm.customerCredits) && vm.customerCredits < countSubscribeButtons && vm.customerCredits !== 0) {
        countSubscribeButtons = vm.customerCredits;
      }
      if (buttonDataArray.length < vm.maxCountOfButtonsForSubscribeAndReject && vm.customerCredits !== 0) {
        for (i; i < countSubscribeButtons; i++) {
          vm.buttonsData.push({ type: 'subscribe', parcipantId: null });
        }
      }
    }

    function cancelParticipation(eventParticipantId) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          var participantObject = {
            eventParticipantStatus: rejectedStatus.id
          };
          // Set Rejected
          return EventParticipantFactory.one(eventParticipantId).patch(participantObject).then(function () {
            //reload data
            vm.customerCredits++;
            return reloadData();
          });
        });
    }

    function subscribe() {
      return Promise.resolve(new Promise(function (res) {
        return res(FitnessEventParticipationService.extraSubscribe($modalInstance.eventInstance, $modalInstance.customerContact));
      })).then(function () {
        vm.customerCredits--;
        return reloadData();
      });
    }

    function close() {
      $modalInstance.close();
    }
  }
}());
