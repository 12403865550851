(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:ConfirmationPromptCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('ConfirmationPromptCtrl', ConfirmationPromptCtrl);

  function ConfirmationPromptCtrl(title, body) {
    var vm = this;
    vm.title = title;
    vm.body = body;
  }
}());
