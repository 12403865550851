(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserActivationCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('UserActivationCtrl', UserActivationCtrl);

  function UserActivationCtrl(
    $location,
    $state,
    $stateParams,
    $scope,
    $rootScope,
    $localStorage,
    UtilsFactory,
    EndpointFactory,
    Restangular
  ) {
    var vm = this;
    vm.errorMessage = null;
    vm.passwordType = 'activation';

    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['user-activation', 'login']
    };
    $rootScope.environment = $localStorage.selectedEnvironment;

    vm.entity = null;
    vm.item = {
      token: $stateParams.token,
      plainPassword: null,
      plainPasswordVerification: null
    };

    vm.backToEnvironments = backToEnvironments;
    vm.isValid = isValid;
    vm.activate = activate;

    $scope.$on('$viewContentLoaded', function () {
      if (UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $localStorage.redirectLink = '';
      } else {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      }
    });

    function backToEnvironments() {
      $localStorage.redirectLink = $location.path();
      $state.go('kiosk.choose_site');
    }

    function isValid() {
      return angular.isDefined(vm.item) &&
        angular.isDefined(vm.item.token) &&
        angular.isString(vm.item.token) &&
        vm.item.token.length &&
        angular.isDefined(vm.item.plainPassword) &&
        angular.isString(vm.item.plainPassword) &&
        vm.item.plainPassword.length &&
        angular.isDefined(vm.item.plainPasswordVerification) &&
        angular.isString(vm.item.plainPasswordVerification) &&
        vm.item.plainPasswordVerification.length &&
        (vm.item.plainPassword === vm.item.plainPasswordVerification);
    }

    function activate() {
      var object = {
        token: vm.item.token
      };

      vm.errorMessage = null;

      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/activate').customPOST(object).then(function (actResult) {
        vm.item.token = actResult.token;
        object.token = vm.item.token;
        object.plainPassword = vm.item.plainPassword;

        Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/password/reset/confirm').customPOST(object).then(function (pwResult) {
          vm.entity = pwResult;
        }, function () {
          vm.errorMessage = 'app.errors.could-not-set-password';
        });
      }, function () {
        vm.errorMessage = 'app.errors.could-not-activate-account';
      });
    }
  }
}());
