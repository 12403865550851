(function () {
  'use strict';

  angular
    .module('sportoase')
    .config(routeConfig)
    .config(translationConfig);

  function routeConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('');
  }

  function translationConfig($translateProvider, PARAMS, $windowProvider) {
    var lang = getPreferredLocale(PARAMS, $windowProvider);
    // get warnings in the developer console regarding forgotten IDs in translations
    $translateProvider.useMissingTranslationHandlerLog();

    $translateProvider.useStaticFilesLoader({
      // path to translation files
      prefix: 'resources/locale-',
      suffix: '.json?t=' + (new Date().getTime())
    });
    $translateProvider.useSanitizeValueStrategy('escape');
    $translateProvider.preferredLanguage(lang);
    $translateProvider.use(lang);
    $translateProvider.useLocalStorage();
  }

  function getPreferredLocale(PARAMS, $windowProvider) {
    var userLocale, userLanguage = 1, hostname = $windowProvider.$get().location.host;

    /* eslint-disable */
    userLocale = (navigator.language || navigator.userLanguage);
    /* eslint-enable */

    if (PARAMS.HOST_LANGUAGE[hostname]) {
      userLanguage = PARAMS.HOST_LANGUAGE[hostname];
      // eslint-disable-next-line no-undef
    } else {
      userLanguage = userLocale;
      if (userLanguage.indexOf('-') > -1) {
        userLanguage = userLanguage.substring(0, userLanguage.indexOf('-')).toLowerCase();
      }
    }

    switch (userLanguage) {
      case 'nl':
        return 'NL_BE';
      case 'en':
        return 'EN_US';
      case 'fr':
        return 'FR_FR';
      default:
        return 'NL_BE';
    }
  }
}());
