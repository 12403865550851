(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:ProductFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('ProductFactory', ProductFactory);

  function ProductFactory(Restangular) {
    var productService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'product'});
    })
    .service('products');

    return {
      getProducts: function () {
        return productService.one('for_webshop').get();
      },
      buyProduct: function (product) {
        return productService.one(product.id).one('buy').post();
      },
      checkoutShoppingCart: function (cart) {
        return productService.one('cart').post('checkout', cart);
      },
      getProductsByLabelType: function (label) {
        return productService.one('for_webshop').get({category: label});
      },
      getProductsByCategories: function (category) {
        return productService.one('for_webshop').get({'category[]': category});
      },
      getProductsByOptions: function (options) {
        return productService.one('for_webshop').get(options);
      },
      getProductById: function (id) {
        return productService.one('for_webshop').one(id).get();
      }
    };
  }
}());
