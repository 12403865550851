(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserCreateAccountCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('UserCreateAccountCtrl', UserCreateAccountCtrl);

  function UserCreateAccountCtrl(
    $document,
    $filter,
    $rootScope,
    $modalInstance,
    EndpointFactory,
    Restangular,
    $translate,
    email
  ) {
    var vm = this;
    vm.ifSuccess = false;
    vm.email = email ? email : null;
    vm.birthday = '';
    vm.username = '';
    vm.firstName = '';
    vm.lastName = '';
    vm.phoneNumber = '';
    vm.street = '';
    vm.streetNumber = '';
    vm.postalCode = '';
    vm.city = '';
    vm.errorMessage = null;
    vm.errorMessageContent = null;
    vm.successMessage = null;
    vm.site = null;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.cancel = cancel;
    vm.createNewAccount = createNewAccount;
    vm.createNewAccountForExistCustomer = createNewAccountForExistCustomer;
    vm.createNewAccountWithData = createNewAccountWithData;
    vm.birthdayDatePickerMaxDate = new Date();

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function removeTimezone(dateWithTime) {
      return $filter('date')(dateWithTime, 'yyyy-MM-dd');
    }

    function createNewAccount() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        phoneNumber: vm.phoneNumber,
        firstName: vm.firstName,
        lastName: vm.lastName,
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate()
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-with-customer').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function createNewAccountWithData() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        phoneNumber: vm.phoneNumber,
        firstName: vm.firstName,
        lastName: vm.lastName,
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate(),
        street: vm.street,
        streetNumber: vm.streetNumber,
        postalCode: vm.postalCode,
        city: vm.city,
        countryCode: 'BE'
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-with-customer-or-without-customer').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function createNewAccountForExistCustomer() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate()
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function getLocaleFromTranslate() {
      var result = $translate.use().split('_')[0].toLowerCase();
      return result;
    }

    function disableSubmitButton(state) {
      angular.element($document[0].getElementById('create-new-account-submit-button'))[0].disabled = state;
    }
  }
}());
