(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:UserMeFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('UserMeFactory', UserMeFactory);

  function UserMeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'me'});
    })
    .service('me');
  }
}());
