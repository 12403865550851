(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventParticipantStatusFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('EventParticipantStatusFactory', EventParticipantStatusFactory);

  function EventParticipantStatusFactory(
    Restangular,
    RestUtilsFactory,
    $log,
    _
  ) {
    var statusService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipantStatus'});
    })
    .service('event_participants/statuses');

    return {
      getList: function (params) {
        return RestUtilsFactory.getFullList(statusService, params);
      },
      one: function (fragment) {
        return statusService.one(fragment);
      },
      getStatusByCode: function (code) {
        var status = null,
            params = { limit: 99 };

        code = code.toUpperCase();
        params['filter[]'] = 'code,' + code;
        // results will be cached so this should only cause a delay once
        return statusService.getList(params)
        .then(function (eventParticipantStatuses) {
          status = _.find(eventParticipantStatuses, function (item) {
            return item.code === code;
          });

          if (status) {
            return status;
          }
          $log.debug('EventParticipantStatusFactory::getStatusByCode() -> Unknown status code ' + code);
          return null;
        });
      }
    };
  }
}());
