(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name kiosk.factory:CurrentUserContextFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .factory('CurrentUserContextFactory', CurrentUserContextFactory);

  function CurrentUserContextFactory(
     $cookies,
     $log,
     $rootScope,
     UserMeFactory,
     UserContextFactory,
     UtilService
    ) {
    return {
      getSiteId: function () {
        return this.getUserContextCookies().siteId;
      },
      getSiteFacilityId: function () {
        return this.getUserContextCookies().siteFacilityId;
      },
      getUserContextId: function () {
        return this.getUserContextCookies().contextId;
      },
      getUserId: function () {
        return this.getUserContextCookies().userId;
      },
      getUserName: function () {
        return this.getUserContextCookies().userName;
      },
      getSiteName: function () {
        return this.getUserContextCookies().siteName;
      },
      getUserContextCookies: function () {
        return {
          siteId: $cookies.get('currentSiteId'),
          userName: $cookies.get('currentUsername'),
          userId: $cookies.get('userId'),
          sitename: $cookies.get('currentSitename'),
          contextId: $cookies.get('currentContextId'),
          siteFacilityId: $cookies.get('siteFacilityId')
        };
      },
      setUserContextCookie: function (userContextId) {
        if (UtilService.isNotEmpty(userContextId)) {
          $cookies.put('userContextId', userContextId);
        }
      },
      isUserContextSelected: function () {
        var cookie = this.getUserContextId();
        return UtilService.isNotEmpty(cookie);
      },
      removeUserContextCookie: function () {
        $log.debug('Removing user context cookie');
        $cookies.remove('userContextId');
      },
      autoSelectUserContext: function (user) {
        var that = this, userContextObject = {user: this.getUserId(), site: $rootScope.environment.siteId};

        return new Promise(function (resolve, reject) {
          var selectedUserContext = null;

          if (UtilService.isEmpty(user.userContexts)) {
            reject(new Error('user has no user contexts'));
          }
          if (UtilService.isEmpty($rootScope.environment) || UtilService.isEmpty($rootScope.environment.siteId)) {
            reject(new Error('environment has no siteId set'));
          }
          // we set the user context for which siteId matches the selected enviromnent siteId
          angular.forEach(user.userContexts, function (userContext) {
            if (userContext.site.id === $rootScope.environment.siteId) {
              selectedUserContext = userContext;
            }
          });

          if (selectedUserContext === null) {
            UserContextFactory.post(userContextObject).then(function (result) {
              UserMeFactory.one().patch({activeUserContext: result.id}).then(function () {
                that.setUserContextCookie(result.id);
                resolve(result);
              }, function () {
                reject(new Error('unable to patch active user context'));
              });
            }, function () {
              reject(new Error('no user context matches the environment'));
            });
            return;
          }

          UserMeFactory.one().patch({activeUserContext: selectedUserContext.id}).then(function () {
            that.setUserContextCookie(selectedUserContext.id);
            resolve(selectedUserContext);
          }, function () {
            reject(new Error('unable to patch active user context'));
          });
        });
      },
      set: function (cookie, value) {
        $cookies.put(cookie, value);
      }
    };
  }
}());
