(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('EventFactory', EventFactory);

  function EventFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'event'});
    })
    .service('events');
  }
}());
