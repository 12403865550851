(function () {
  'use strict';

  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('set-locale', {
        url: '/set-locale/:locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.setLocale($stateParams.locale);
          $state.go('kiosk', {}, {reload: true});
        }]
      })
      .state('toggle-locale', {
        url: '/toggle-locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.toggleLocale();
          $state.go('kiosk', {}, {reload: true});
        }]
      });
  }
}());
