(function () {
  'use strict';

  angular
    .module('kiosk')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('kiosk', {
        url: '/',
        views: {
          '@': {
            templateUrl: 'kiosk/views/kiosk.tpl.html'
          },
          'content@kiosk': {
            // templateUrl: 'kiosk/views/kiosk.environment.tpl.html',
            //second variant no cache for template
            templateUrl: 'kiosk/views/kiosk.environment.tpl.html?v' + Date.now(),
            //templateUrl: 'kiosk/views/kiosk.environmentclose.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.login', {
        url: 'login',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.login.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope, $log) {
          $log.debug('enter kiosk state');
          $rootScope.ignoreAuthenticationErrors = true;
        },
        onExit: function ($rootScope, $log) {
          $log.debug('exit kiosk state');
          $rootScope.ignoreAuthenticationErrors = false;
        }
      })
      .state('kiosk.customer', {
        url: 'customer/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.customer.tpl.html?v' + Date.now(),
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest', {
        url: 'guest/:customerId/dynamic/:isGroupLesson',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest_login', {
        url: 'guest/login/:token',
        views: {
          'content@kiosk': {
            template: ' ',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.guest_edit', {
        url: 'guest/:customerId/edit',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest_edit.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('user_activation', {
        url: '/users/activate/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_activation': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserActivationCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('user_password_reset', {
        url: '/users/password/reset/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_password_reset': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserPasswordResetCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.choose_site', {
        url: 'choose_site',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.choose_site.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.user', {
        url: 'user/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.user.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.register_guest_user', {
        url: 'register_guest_user/:isGuest/:isGroupLesson',
        views: {
          'content@kiosk': {
            template: '<div class="modal-body">\n' +
              '  {{ "app.loading" | translate | uconlyfirst }}\n' +
              '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
              '          <span class="icons">\n' +
              '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
              '          </span>\n' +
              '      </span>\n' +
              '</div>',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.swimmingschool-payment_login', {
        url: 'swimmingschool-payment/:token',
        views: {
          'content@kiosk': {
            template: ' ',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.swimmingschool-customer_login_as_guest', {
        url: 'swimmingschool-payment-login-customer/:token',
        views: {
          'content@kiosk': {
            template: '<div class="modal-body">\n' +
              '  {{ "app.loading" | translate | uconlyfirst }}\n' +
              '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
              '          <span class="icons">\n' +
              '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
              '          </span>\n' +
              '      </span>\n' +
              '</div>',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.swimmingschool-payment_process_payment', {
        url: 'swimmingschool-payment/:customerId/payment',
        params: {
          subscriptionId: null,
          productId: null
        },
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.swimmingschool-payment.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      ;
  }
}());
