(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:capacityIndicator
   * @restrict AEC
   * in template insert <capacity-indicator datasource="{{ eventInstance }}"></capacity-indicator>
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('capacityIndicator', capacityIndicator);

  function capacityIndicator() {
    //will be root (one) div
    var currentIndicator = '            <div>\n' +
      '              <div class="guest__capacity-indicator-svg" ng-if="(capacityPercentageForEvent >= 0 && capacityPercentageForEvent <= 30)">\n' +
      '                <ng-include src="\'images/svg/green-indicator.svg\'"></ng-include>\n' +
      '                <div class="guest__capacity-indicator-text">{{\'guest_capacity_indicator_green\' | translate}}</div>\n' +
      '              </div>\n' +
      '              <div class="guest__capacity-indicator-svg" ng-if="capacityPercentageForEvent > 30 && capacityPercentageForEvent <= 60">\n' +
      '                <ng-include src="\'images/svg/yellow-indicator.svg\'"></ng-include>\n' +
      '                <div class="guest__capacity-indicator-text">{{\'guest_capacity_indicator_yellow\' | translate}}</div>\n' +
      '              </div>\n' +
      '              <div class="guest__capacity-indicator-svg" ng-if="(capacityPercentageForEvent > 60 && capacityPercentageForEvent <= 90)">\n' +
      '                <ng-include src="\'images/svg/orange-indicator.svg\'"></ng-include>\n' +
      '                <div class="guest__capacity-indicator-text">{{\'guest_capacity_indicator_orange\' | translate}}</div>\n' +
      '              </div>\n' +
      '              <div class="guest__capacity-indicator-svg" ng-if="(capacityPercentageForEvent > 90)">\n' +
      '                <ng-include src="\'images/svg/red-indicator.svg\'"></ng-include>\n' +
      '                <div class="guest__capacity-indicator-text">{{\'guest_capacity_indicator_red\' | translate}}</div>\n' +
      '              </div>\n' +
      '            </div>';
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        maximummembers: '@',
        numberofvacancies: '@'
      },
      controller: ['$scope', function ($scope) {
        $scope.capacityPercentageForEvent = Number($scope.numberofvacancies) === 1 ? 91 : ((100 / Number($scope.maximummembers)) * (Number($scope.maximummembers) - Number($scope.numberofvacancies)));
      }],
      template: currentIndicator
    };
  }
}());
