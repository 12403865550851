(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.factory:UtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .service('UtilService', UtilService);

  function UtilService($state) {
    var vm = this;

    vm.isNotEmpty = function (variable) {
      var notNull = angular.isDefined(variable) && variable !== null;
      if (!notNull) {
        return false;
      }
      if (angular.isString(variable) || angular.isArray(variable)) {
        return variable.length > 0;
      }
      if (angular.isObject(variable) && !angular.isDate(variable)) {
        return Object.keys(variable).length > 0;
      }

      return true;
    };

    vm.isEmpty = function (variable) {
      return !vm.isNotEmpty(variable);
    };

    vm.reloadState = function () {
      $state.go($state.current, {}, {reload: true, notify: true});
    };
  }
}());
