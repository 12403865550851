(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('KioskFactory', KioskFactory);

  function KioskFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'kiosk'});
    })
    .service('kiosks');
  }
}());
