(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.service:FitnessEventParticipationService
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .service('FitnessEventParticipationService', FitnessEventParticipationService);

  function FitnessEventParticipationService(
    $rootScope,
    $log,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    EventParticipantTypeFactory,
    EventMultipleRejectParticipantFactory
  ) {
    var vm = this;

    // variables
    vm.eventInstance = undefined;
    vm.customerContact = undefined;
    vm.myParticipation = undefined;
    vm.participations = [];

    // methods
    vm.cancel = cancel;
    vm.multipleCancel = multipleCancel;
    vm.subscribe = subscribe;
    vm.extraSubscribe = extraSubscribe;
    vm.subscribeRejectedParcipants = subscribeRejectedParcipants;

    function cancel(eventInstance) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          // Set Rejected
          return EventParticipantFactory.one(eventInstance.userStatus.id).patch({
            eventParticipantStatus: rejectedStatus.id
          }).then(function () {
            $log.debug('Rejected');
          });
        });
    }

    function multipleCancel(eventInstance) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          // Set Rejected
          return EventMultipleRejectParticipantFactory.one(eventInstance.userStatus.id).patch({
            eventParticipantStatus: rejectedStatus.id
          }).then(function () {
            $log.debug('Multiple Rejected');
          });
        });
    }

    function subscribeRejectedParcipants(eventInstance, customerContact) {
      var participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);

/*      if (angular.isDefined(participant.eventParticipantStatus) && participant !== 'participant') {
        console.log('eventInstance: ', eventInstance);
        return EventParticipantStatusFactory.getStatusByCode((participant.eventParticipantStatus.code === 'TENTATIVE') || (participant.eventParticipantStatus.code === 'REJECTED' && participant.numberOfVacancies > 0) ? 'accepted' : 'pending')
          .then(function (status) {
            // from waitinglist
            participantObject.eventParticipantStatus = status.id;

            // Set Accepted
            return EventParticipantFactory.one(participant.id).patch(participantObject);
          });
      }*/

      return EventParticipantStatusFactory.getStatusByCode('pending')
        .then(function (pendingStatus) {
          return EventParticipantTypeFactory.getTypeByCode('participant')
            .then(function (participantType) {
              participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);
              participantObject.eventParticipantStatus = pendingStatus.id;
              participantObject.eventParticipantType = participantType.id;
              participantObject.contact = customerContact.contact.id;
              participantObject.event = eventInstance.eventId;
              participantObject.site = $rootScope.environment.siteId;
              $log.debug('EventParticipationService.subscribe.participantObject', participantObject);

              return EventParticipantFactory.post(participantObject)
                .then(function (newParticipation) {
                  participantObject.myParticipation = newParticipation;

                  if (participantObject.myParticipation.eventParticipantStatus.code === 'TENTATIVE') {
                    return EventParticipantStatusFactory.getStatusByCode('accepted')
                      .then(function (acceptedStatus) {
                        participantObject = {
                          eventParticipantStatus: acceptedStatus.id
                        };
                        // Set Accepted
                        return EventParticipantFactory.one(newParticipation.id).patch(participantObject)
                          .then(function () {
                            $log.debug('Accepted:', newParticipation);
                            participantObject.myParticipation.eventParticipantStatus.code = 'ACCEPTED';
                          }, function () {
                            $log.debug('Not Accepted', newParticipation);
                          });
                      });
                  }

                  $log.debug('Waiting List');
                });
            });
        });
    }

    function subscribe(eventInstance, customerContact) {
      var participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);

      if (angular.isDefined(eventInstance.userStatus)) {
        return EventParticipantStatusFactory.getStatusByCode((eventInstance.userStatus.code === 'TENTATIVE') || (eventInstance.userStatus.code === 'REJECTED' && eventInstance.numberOfVacancies > 0) ? 'accepted' : 'pending')
          .then(function (status) {
            // from waitinglist
            participantObject.eventParticipantStatus = status.id;
            // Set Accepted
            return EventParticipantFactory.one(eventInstance.userStatus.id).patch(participantObject);
          });
      }

      return EventParticipantStatusFactory.getStatusByCode('pending')
        .then(function (pendingStatus) {
          return EventParticipantTypeFactory.getTypeByCode('participant')
            .then(function (participantType) {
              participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);
              participantObject.eventParticipantStatus = pendingStatus.id;
              participantObject.eventParticipantType = participantType.id;

              $log.debug('EventParticipationService.subscribe.participantObject', participantObject);

              return EventParticipantFactory.post(participantObject)
                .then(function (newParticipation) {
                  participantObject.myParticipation = newParticipation;

                  if (participantObject.myParticipation.eventParticipantStatus.code === 'TENTATIVE') {
                    return EventParticipantStatusFactory.getStatusByCode('accepted')
                      .then(function (acceptedStatus) {
                        participantObject = {
                          eventParticipantStatus: acceptedStatus.id
                        };
                        // Set Accepted
                        return EventParticipantFactory.one(newParticipation.id).patch(participantObject)
                          .then(function () {
                            $log.debug('Accepted:', newParticipation);
                            participantObject.myParticipation.eventParticipantStatus.code = 'ACCEPTED';
                          }, function () {
                            $log.debug('Not Accepted', newParticipation);
                          });
                      });
                  }

                  $log.debug('Waiting List');
                });
            });
        });
    }

    function extraSubscribe(eventInstance, customerContact) {
      var participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);
      return EventParticipantTypeFactory.getTypeByCode('participant')
        .then(function (participantType) {
          participantObject.eventParticipantType = participantType.id;
          if (angular.isDefined(eventInstance.userStatus)) {
            return EventParticipantStatusFactory.getStatusByCode('accepted')
              .then(function (status) {
                participantObject.eventParticipantStatus = status.id;
                participantObject.contact = customerContact.contact.id;
                participantObject.event = eventInstance.eventId;
                participantObject.site = $rootScope.environment.siteId;
                return EventParticipantFactory.post(participantObject);
              });
          }
        });
    }
    return vm;
  }
}());
