(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:Fitness
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('FitnessFactory', FitnessFactory);

  function FitnessFactory(
    $log,
    CustomerManagementFactory,
    FitnessEventParticipationService
  ) {
    var vm = this;

    // variables
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };

    // methods
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    vm.loadJournals = loadJournals;
    vm.participation = FitnessEventParticipationService.participation;

    function loadCompleteFitnessData(customer, customerContact) {
      $log.debug('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ', customerContact);
      return CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, ['journals', 'credits', 'eventCategories']).then(function (results) {
        $log.debug('FitnessFactory::loadJournals() -> data: ', results);
        return results;
      });
    }

    function loadJournals(customer, customerContact) {
      $log.debug('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ', customerContact);
      CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, ['journals', 'credits']).then(function (results) {
        $log.debug('FitnessFactory::loadJournals() -> data: ', results);
        return results;
      });
    }
    return vm;
  }
}());
