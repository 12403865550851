(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:MyEntitiesFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('MyEntitiesFactory', MyEntitiesFactory);

  function MyEntitiesFactory(UserMeFactory) {
    return {
      getCustomer: function (params) {
        return UserMeFactory.one('customer').get(params);
      },
      getContact: function (params) {
        return UserMeFactory.one('contact').get(params);
      },
      getFitnessCredits: function () {
        return UserMeFactory.one('fitness_credits').get();
      }
    };
  }
}());
