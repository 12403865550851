(function () {
  'use strict';
  // Async bootstrap made possible by Marius Schulz
  // https://blog.mariusschulz.com/2014/10/22/asynchronously-bootstrapping-angularjs-applications-with-server-side-data
  angular
    .module('sportoase.config', []);

  fetchData();

  function fetchData() {
    var $http = angular.injector(['ng']).get('$http'),
        /* eslint-env browser */
        selectedBackend = (localStorage.getItem('ngStorage-selectedBackend') ? localStorage.getItem('ngStorage-selectedBackend') : 0);

    $http.get('../env/env_vars.json?t=' + (new Date().getTime())).then(function (response) {
      var environments = response.data.OAUTH_CONFIG,
          config = response.data.OAUTH_CONFIG[selectedBackend],
          params = response.data.PARAMS;

      angular
        .module('sportoase.config')
        .constant('ENVIRONMENTS', environments)
        .constant('OAUTH_CONFIG', config)
        .constant('PARAMS', params);

      bootstrapApplication();
    }, function (errorResponse) {
      // Handle error case
      console.log('An error occured while loading the authentication configuration file', errorResponse);
    });
  }

  function bootstrapApplication() {
    var $document = angular.injector(['ng']).get('$document');
    angular.element($document).ready(function () {
      console.log('Bootstrap config data received. Angular app will be booting now.');
      console.log('document is: ', $document);
      angular.bootstrap($document, ['sportoase']);
    });
  }
}());
