(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserContextSwitchCtrl
   *
   * @descriptionice('events');
  }
   *
   */
  angular
    .module('sportoase')
    .controller('UserContextSwitchCtrl', UserContextSwitchCtrl);

  function UserContextSwitchCtrl(
    $localStorage,
    $modalInstance,
    CurrentUserContextFactory,
    sites,
    UserContextFactory
  ) {
    var vm = this;
    vm.sites = sites;
    vm.changeUserContext = changeUserContext;

    function changeUserContext(site) {
      var userContextObject = null;

      $localStorage.userContexts.forEach(function (uc) {
        if (site.siteId === uc.site.id) {
          userContextObject = uc;
        }
      });

      if (!userContextObject) {
        userContextObject = {user: CurrentUserContextFactory.getUserId(), site: site.siteId};
        UserContextFactory.post(userContextObject).then(function (result) {
          $modalInstance.close(result);
        });
        return;
      }

      $modalInstance.close(userContextObject);
    }
  }
}());
