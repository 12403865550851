(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventParticipantTypeFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('EventParticipantTypeFactory', EventParticipantTypeFactory);

  function EventParticipantTypeFactory(
    Restangular,
    RestUtilsFactory,
    $log,
    _
  ) {
    var typeService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipantType'});
    })
    .service('events/participants/types');

    return {
      getList: function (params) {
        return RestUtilsFactory.getFullList(typeService, params);
      },
      one: function (fragment) {
        return typeService.one(fragment);
      },
      getTypeByCode: function (code) {
        var status = null,
            params = { limit: 99 };

        code = code.toUpperCase();
        params['filter[]'] = 'code,' + code;
        // journalActions will be cached so this should only cause a delay once
        return typeService.getList(params)
        .then(function (eventParticipantTypes) {
          status = _.find(eventParticipantTypes, function (item) {
            return item.code === code;
          });

          if (status) {
            return status;
          }
          $log.debug('EventParticipantTypeFactory::getStatusByCode() -> Unknown type code ' + code);
          return null;
        });
      }
    };
  }
}());
