(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventMultipleRejectParticipantFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('EventMultipleRejectParticipantFactory', EventMultipleRejectParticipantFactory);

  function EventMultipleRejectParticipantFactory(Restangular, CurrentUserContextFactory, $log) {
    var factory = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipant'});
      })
      .service('event_multiple_participants');

    factory.getParticipationObject = function (eventInstance, customerContact) {
      var participation = {};

      $log.debug('new Multiple EventParticipationService instance');
      $log.debug('EventParticipationService.eventInstanceId', eventInstance.id);

      participation.eventInstance = eventInstance.id;

      if (angular.isUndefined(eventInstance.userStatus)) {
        participation.contact = customerContact.contact.id;
        participation.site = eventInstance.site;
        participation.event = eventInstance.eventId;
      }

      return participation;
    };

    return factory;
  }
}());
