(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:CustomerManagementFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('CustomerManagementFactory', CustomerManagementFactory);

  function CustomerManagementFactory(
    Restangular
  ) {
    var detailsService =
      Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'posDetails'});
      })
      .service('services/details')
    ;

    return {
      getCustomerPosDetails: function (customerId, contactId) {
        return this.getCustomerDetails(customerId, contactId, ['journals']);
      },
      getCustomerDetails: function (customerId, contactId, contains, params) {
        var detailsParams = angular.isUndefined(params) ? {} : params;

        if (angular.isDefined(contains)) {
          detailsParams['contains[]'] = [];
          angular.forEach(contains, function (contain) {
            detailsParams['contains[]'].push(contain);
          });
        }

        return detailsService.one('customers').one(customerId).one('contacts').one(contactId).get(detailsParams);
      }
    };
  }
}());
