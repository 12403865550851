(function () {
  'use strict';

  // filters
  angular.module('filters', [])

  // uconlyfirst filter
  .filter('uconlyfirst', function () {
    return function (input) {
      return angular.isDefined(input) ? input.charAt(0).toUpperCase() + input.slice(1) : input;
    };
  })

  // yesno filter
  .filter('yesno', function (translateFilter) {
    return function (input) {
      if (input === true || input > 0) {
        return translateFilter('app.yes');
      } else if (input === false || input <= 0) {
        return translateFilter('app.no');
      }
      return input;
    };
  })

  // range filter
  .filter('range', function () {
    return function (input, min, max) {
      var i;
      min = parseInt(min, 10);
      max = parseInt(max, 10);
      for (i = min; i <= max; i++) {
        input.push(i);
      }
      return input;
    };
  })

  // string to HTML filter
  .filter('trustHtml', function ($sce) {
    return function (html) {
      return $sce.trustAsHtml(html);
    };
  });
}());
