(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:AttachmentUtilsFactory
   *
   * @description
   *
   */
  angular
    .module('utils')
    .factory('AttachmentUtilsFactory', AttachmentUtilsFactory);

  function AttachmentUtilsFactory(Restangular, OAuthToken, $window, $log) {
    return {
      download: function (url, parameters) {
        var actualParameters = parameters || {},
            actualUrl = Restangular.configuration.baseUrl + url,
            uriObject,
            uri;

        /* eslint-disable camelcase */
        if (angular.isUndefined(actualParameters.access_token)) {
          actualParameters.access_token = OAuthToken.getAccessToken();
        }
        /* eslint-enable camelcase */

        /* global URI */
        uriObject = new URI(actualUrl);
        uriObject.setQuery(actualParameters);
        uri = uriObject.toString();

        $log.debug('ATTACHMENT_UTILS: Downloading ', uri);

        $window.open(uri, '_blank');

        return uri;
      }
    };
  }
}());
