(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:FitnessCourseFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('FitnessCourseFactory', FitnessCourseFactory);

  function FitnessCourseFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'fitnessCourse'});
    })
    .service('fitness/courses');
  }
}());
