(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:UserContextFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('UserContextFactory', UserMeFactory);

  function UserMeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'userContext'});
    })
      .service('user_contexts');
  }
}());
