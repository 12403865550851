(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserSelectActionAccountCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('UserSelectActionAccountCtrl', UserSelectActionAccountCtrl);

  function UserSelectActionAccountCtrl($log, $modal, $modalInstance) {
    var vm = this;
    vm.createNewAccountForNewCustomer = createNewAccountForNewCustomer;
    vm.createNewAccountForExistCustomer = createNewAccountForExistCustomer;
    vm.cancel = cancel;

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function createNewAccountForNewCustomer() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/create_new_account_for_new_customer.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }
    function createNewAccountForExistCustomer() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_create_account.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }
  }
}());
