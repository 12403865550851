(function () {
  'use strict';

  angular
    .module('sportoase')
    .controller('LandingPageModalCtrl', LandingPageModalCtrl);

  function LandingPageModalCtrl(
    $modalInstance,
    $translate
  ) {
    var vm = this;

    /* ------- PARAMS ------- */
    vm.currentLocale = $translate.use();
    vm.loadingSettingForLandingPage = $modalInstance.loadingSettingForLandingPage;
    /* ----- END PARAMS ----- */

    /* ------- FUNCTIONS ------- */
    vm.cancel = cancel;
    vm.getModalTitle = getModalTitle;
    vm.getModalBody = getModalBody;
    /* ----- END FUNCTIONS ----- */

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function getModalTitle() {
      return getValueFromJsonByName('title');
    }

    function getModalBody() {
      return getValueFromJsonByName('text');
    }

    function getValueFromJsonByName(name) {
      if (vm.loadingSettingForLandingPage && angular.fromJson(vm.loadingSettingForLandingPage)[name]) {
        return angular.fromJson(vm.loadingSettingForLandingPage)[name][vm.currentLocale.split('_')[0]];
      }
      return false;
    }
  }
}());
