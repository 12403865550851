(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name kiosk.service:ShoppingCartService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .factory('ShoppingCartService', ShoppingCartService);

  function ShoppingCartService($filter) {
    var vm = this;
    vm.shoppingCart = {};
    vm.shoppingCartIndividual = {};
    vm.reservationEvent = undefined;
    vm.promocode = undefined;
    vm.promocodeValue = undefined;
    vm.promocodeProducts = undefined;
    vm.guest = {};
    vm.deliveryAddress = {};
    vm.deliveryRequested = false;
    vm.subscriptionId = undefined;
    vm.customerCoin = undefined;

    vm.add = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        vm.shoppingCart[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCart[product.id].quantity += quantity;
      }
    };

    vm.addOrRemoveIndividual = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (quantity < 0) {
        return;
      }
      if (angular.isUndefined(vm.shoppingCartIndividual[product.id])) {
        vm.shoppingCartIndividual[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCartIndividual[product.id].quantity = quantity;
        if (vm.shoppingCartIndividual[product.id].quantity <= 0) {
          delete vm.shoppingCartIndividual[product.id];
        }
      }
    };

    vm.remove = function (product, quantity) {
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        return;
      }
      if (angular.isDefined(quantity) && quantity < vm.shoppingCart[product.id].quantity) {
        vm.shoppingCart[product.id].quantity -= quantity;
      } else {
        delete vm.shoppingCart[product.id];
      }
    };

    vm.totalPrice = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.totalPriceIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.countIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.get = function () {
      return vm.shoppingCart;
    };

    vm.getIndividual = function () {
      return vm.shoppingCartIndividual;
    };

    vm.checkout = function () {
      var payload = {
        products: {},
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null
      };
      Object.values(vm.shoppingCart).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });
      return payload;
    };

    vm.getProduct = function (product) {
      return vm.shoppingCart[product.id];
    };

    vm.setReservationEvent = function (event) {
      vm.reservationEvent = event;
    };

    vm.getReservationEvent = function () {
      return vm.reservationEvent;
    };

    vm.setPromocode = function (promocode) {
      vm.promocode = promocode;
    };

    vm.getPromocodeValue = function () {
      return vm.promocodeValue;
    };

    vm.setPromocodeValue = function (promocodePercent) {
      vm.promocodeValue = promocodePercent;
    };

    vm.setPromocodeType = function (type) {
      vm.promocodeType = type;
    };

    vm.setPromocodeProducts = function (products) {
      vm.promocodeProducts = products;
    };

    vm.getPromocodeProducts = function () {
      return vm.promocodeProducts;
    };

    vm.getPromocode = function () {
      return vm.promocode;
    };

    vm.setSubscriptionId = function (subscriptionId) {
      vm.subscriptionId = subscriptionId;
    };

    vm.getSubscriptionId = function () {
      return vm.subscriptionId;
    };

    vm.setGuest = function (guest) {
      if (angular.isDefined(guest.birthDate)) {
        guest.birthDate = $filter('date')(guest.birthDate, 'yyyy-MM-dd');
      }
      vm.guest = guest;
    };

    vm.setDeliveryAddress = function (deliveryAddress) {
      vm.deliveryAddress = deliveryAddress;
    };

    vm.setDeliveryRequested = function (deliveryRequested) {
      vm.deliveryRequested = deliveryRequested;
    };

    vm.setCustomerCoin = function (customerCoin) {
      vm.customerCoin = customerCoin;
    };

    vm.getCartIndividualTotalDiscount = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          var discount = 0;
          if (vm.promocodeValue) {
            if (vm.promocodeType === 'loyalty') {
              discount = (item.product.webshopPrice * vm.promocodeValue) / 100;
            }
            if (vm.promocodeType === 'percent' && vm.promocodeProducts && vm.promocodeProducts.includes(item.product.id)) {
              discount = (item.product.webshopPrice * vm.promocodeValue) / 100;
            }

            if (vm.promocodeType === 'amount' && vm.promocodeProducts && vm.promocodeProducts.includes(item.product.id)) {
              discount = vm.promocodeValue;
            }
          }
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartTotalDiscount = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          var discount = 0;
          if (vm.promocodeValue) {
            if (vm.promocodeType === 'loyalty') {
              discount = (item.product.webshopPrice * vm.promocodeValue) / 100;
            }
            if (vm.promocodeType === 'percent' && vm.promocodeProducts && vm.promocodeProducts.includes(item.product.id)) {
              discount = (item.product.webshopPrice * vm.promocodeValue) / 100;
            }

            if (vm.promocodeType === 'amount' && vm.promocodeProducts && vm.promocodeProducts.includes(item.product.id)) {
              discount = vm.promocodeValue;
            }
          }
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartIndividualTotalsWithPromocode = function () {
      return vm.totalPriceIndividual() - vm.getCartIndividualTotalDiscount();
    };

    vm.getCartTotalsWithPromocode = function () {
      return vm.totalPrice() - vm.getCartTotalDiscount();
    };

    vm.checkoutForIndividualTickets = function () {
      var payload = {
        products: {},
        reservationEvent: angular.isDefined(vm.reservationEvent) ? vm.reservationEvent.id : null,
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null,
        guest: vm.guest
      };

      if (angular.isDefined(vm.subscriptionId)) {
        payload.subscriptionId = vm.subscriptionId;
      }

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });

      return payload;
    };

    vm.checkoutGiftProductsCart = function () {
      var payload = {
        products: {},
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null,
        guest: vm.guest,
        deliveryAddress: vm.deliveryAddress,
        deliveryRequested: vm.deliveryRequested
      };

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });

      return payload;
    };

    vm.getProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCart).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getIndividualProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getTotalQuantity = function () {
      var total = 0;
      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        total += item.quantity;
      });
      return total;
    };

    vm.clear = function () {
      vm.shoppingCart = {};
      vm.shoppingCartIndividual = {};
      vm.reservationEvent = undefined;
      vm.promocode = undefined;
      vm.promocodeValue = undefined;
      vm.promocodeProducts = undefined;
      vm.customerCoin = undefined;
    };

    vm.getIndividualProductsJournalTypes = function () {
      var journalTypes = [];

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        if (angular.isDefined(item.product.journalType) && journalTypes.indexOf(item.product.journalType) === -1) {
          journalTypes.push(item.product.journalType);
        }
      });

      return journalTypes;
    };

    vm.getTotalCoinsDiscount = function () {
      return angular.isDefined(vm.customerCoin) && angular.isDefined(vm.customerCoin.amountInEuro) && vm.getAmountProductsWithJournal() > 0 ?
        vm.customerCoin.amountInEuro : 0;
    };

    vm.getAmountProductsWithJournal = function () {
      var productsWithJournals = Object.values(vm.shoppingCart).filter(function (item) {
        return angular.isDefined(item.product.journalType) && angular.isDefined(item.product.applicableForCoinsDiscount) && item.product.applicableForCoinsDiscount;
      });

      return productsWithJournals.length;
    };

    return vm;
  }
}());
