(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:SubscriptionFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('SubscriptionFactory', SubscriptionFactory);

  function SubscriptionFactory(Restangular) {
    var subscriptionService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'subscription'});
    })
    .service('swim_courses/subscriptions');

    return {
      getSubscriptionById: function (id) {
        return subscriptionService.one(id).one('for_webshop').get();
      }
    };
  }
}());
